import { client } from "api/client.api";
import { ExportModel, List, OfferModel } from "models";
import { CreateExportData, MatchMd5sData } from "api/optizmo/offer-md5s/offer-md5s.types";
import { fileUploadMiddleware } from "api/file-upload.api.middleware";
import { Md5UploadData } from "pages/md5/import/md5-file-import/md5-file-import.types";
import { Md5JobModel } from "models/offer-md5.model";

export const createOfferMd5s = client.createRequest<OfferModel, MatchMd5sData>()({
  method: "POST",
  endpoint: "/md5s/match",
});

export const createExport = client.createRequest<ExportModel, CreateExportData>()({
  method: "POST",
  endpoint: "/md5s/export",
});

export const getExportJobs = client.createRequest<List<ExportModel>>()({
  method: "GET",
  endpoint: "/md5s/export-jobs",
  options: { timeout: 0 },
});

export const downloadExportJob = client.createRequest<string>()({
  method: "GET",
  endpoint: "/md5s/export-jobs/:jobId/download-url",
  options: { timeout: 0 },
});

export const getMd5Jobs = client.createRequest<List<Md5JobModel>>()({
  method: "GET",
  endpoint: "/md5s/match-jobs",
  options: { timeout: 0 },
});

export const md5JobDownloadFile = client.createRequest<string>()({
  method: "GET",
  endpoint: "/md5s/match-jobs/:jobId/download-url",
  options: { timeout: 0 },
});

export const uploadMd5 = fileUploadMiddleware<undefined, Md5UploadData>()({
  method: "post",
  endpoint: "/md5s/upload",
  isFormData: true,
});
