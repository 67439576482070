import { FC, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form, FormikProps } from "formik";
import {
  Button,
  MessageField,
  FormButtons,
  FormField,
  notification,
  Modal,
  Label,
  Switch,
} from "@epcnetwork/core-ui-kit";

import { updateCompany, UpdateCompanyData } from "api";
import { UpdateOptizmoCompanyProps } from "./update-optizmo-company.types";
import { validationSchema, initialValues } from "./update-optizmo-company.constants";

import styles from "../update-optizmo.module.scss";

export const UpdateOptizmoCompany: FC<UpdateOptizmoCompanyProps> = ({ isOpen, company, close, onUpdateSuccess }) => {
  const [tokenEnabled, setTokenEnabled] = useState<boolean>(false);

  const { submit, submitting, error, onSubmitSuccess, onSubmitError } = useSubmit(updateCompany);
  onSubmitSuccess(({ response }) => {
    onUpdateSuccess(response);
    close();
    notification.success("Optizmo company updated!", "Company has been successfully updated.");
  });
  onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the company");
  });

  const handleSwitchToggle =
    (formikProps: FormikProps<Partial<Pick<UpdateCompanyData, "name" | "optizmoToken" | "isActive">>>) =>
    (value: string, checked: boolean) => {
      setTokenEnabled(checked);
      if (!checked) {
        formikProps.setFieldValue("optizmoToken", "");
        formikProps.setFieldTouched("optizmoToken", false, false);
      }
    };

  const handleSubmit = async (values: UpdateCompanyData) => {
    if (company) {
      const { optizmoToken, ...rest } = values;
      const data = tokenEnabled ? values : rest;
      await submit({ data, params: { companyId: company.id } });
    }
  };

  const tokenNotEnabledPlaceholder = company?.optizmo ? "Use the same Optizmo token" : "Do not configure Optizmo token";

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik
        initialValues={initialValues(company)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(tokenEnabled)}
        enableReinitialize
      >
        {(props) => (
          <Form>
            <div className={styles.title}>Update {company?.name} company</div>
            <MessageField message={error?.message || ""} />
            <FormField type="text" name="name" label="Company name" disableFloatingLabel required />

            <div>
              <div className={styles.labelRow}>
                <div>
                  <Label text="Optizmo token" isInputLabel />
                  {/*<p className={styles.projectDescription}>Configure Optizmo token.</p>*/}
                </div>
                <Switch value="test" disableError checked={tokenEnabled} onChange={handleSwitchToggle(props)} />
              </div>
              <FormField
                type="text"
                name="optizmoToken"
                placeholder={tokenEnabled ? "Provide a new Optizmo token" : tokenNotEnabledPlaceholder}
                disableFloatingLabel
                disabled={!tokenEnabled}
              />
            </div>

            <FormButtons className={styles.buttons}>
              <Button appearance="secondary" onClick={close}>
                Cancel
              </Button>
              <Button type="submit" loading={submitting}>
                Submit
              </Button>
            </FormButtons>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
