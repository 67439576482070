import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  Button,
  DropAcceptedFunc,
  FileItem,
  FileUpload,
  notification,
  Nullable,
  useFileQueue,
  isExceedByteSize,
  bytesConverter,
} from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { Formik, Form } from "formik";

import { UNSUBSCRIBE_JOBS_LIST_PAGE } from "constants/routes.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { getInitialData, getSupportedFormats } from "./unsub-from-file.utils";
import { UnsubValuesModal } from "../unsub-values-modal/unsub-values-modal";
import { UnsubFileItem } from "./unsub-from-file.types";
import { UnsubscribeData, UnsubscribeFileInfo, UnsubscribeFormData, unsubscribeFromFile } from "api";
import { validationSchema, initialValues } from "./unsub-from-file.constants";
import { UnsubListSelect } from "../unsub-list-select/unsub-list-select";
import { MAX_UNSUBSCRIBE_EMAILS } from "../create-unsub.constants";

import styles from "./unsub-from-file.module.scss";

export const UnsubFromFile: React.FC = () => {
  const navigate = useNavigate();

  const [editedFile, setEditedFile] = useState<Nullable<UnsubFileItem>>(null);

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitOneByOne,
    hasAllSuccessFiles,
  } = useFileQueue<UnsubFileItem, UnsubscribeData>("Unsub", unsubscribeFromFile);

  const handleDropAccept: DropAcceptedFunc = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (isExceedByteSize(file, 5e6)) {
        notification.error("This CSV is larger than 5MB.", "Please upload a smaller file.");
      } else {
        addFiles(createInitialFile(file, { data: getInitialData(file) }));
      }
    },
    [addFiles, createInitialFile],
  );

  const handleSubmit = async (values: UnsubscribeFormData) => {
    const data = files.map((file) => {
      const formData = new FormData();

      const { fileName, emailIndex, hasHeaders } = file.data;
      const unsubData: UnsubscribeFileInfo = {
        emailIndex,
        hasHeaders,
        data: values.data,
      };

      formData.append("form", JSON.stringify(unsubData));
      formData.append("file", file.originalFile, fileName);

      return {
        id: file.id,
        data: formData,
      };
    });

    await submitOneByOne(data);
  };

  useDidUpdate(() => {
    const haveError = Boolean(files[0]?.error);

    if (isEntityFinished && !haveError) {
      notification.success("Success!", "Emails were added to queue.");

      removeFiles(files[0].id);
      clearEntity();

      navigate(UNSUBSCRIBE_JOBS_LIST_PAGE.path);
    }

    if (isEntityFinished && haveError) {
      const error = files[0]?.error;
      notification.error("Error!", `Could not create a job. ${error}`);
    }
  }, [isEntityFinished]);

  const closeModal = () => setEditedFile(null);

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: UnsubFileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleItemConfiguration = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const getAcceptedFilesText = (formats: string[]): string => `Accepted ${formats.join(", ")} files`;

  const supportedFormats = [csvFormat, txtFormat].flat();
  const MAX_FILE_SIZE = 0.2;

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Upload a CSV file containing the emails you want to unsubscribe.</h2>

      <div className={styles.form}>
        <div>
          <FileUpload
            className={classNames(styles.dropZone, { [styles.uploadZone]: files.length })}
            uploadedFilesLength={files.length}
            subtitle={`${getAcceptedFilesText(getSupportedFormats(supportedFormats))} - max ${MAX_UNSUBSCRIBE_EMAILS} emails.`}
            // subtitle={`${getAcceptedFilesText(getSupportedFormats(supportedFormats))} - max ${MAX_FILE_SIZE}MB.`}
            accept={supportedFormats}
            onDropAccepted={handleDropAccept}
            exceedFilesOption="splice-with-error"
            disabled={!isEntityInConfiguration}
            preventDropOnDocument
            multiple={false}
            maxSize={bytesConverter(MAX_FILE_SIZE, "Bytes", "MB")}
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => {
              const showFileContent = data.emailIndex >= 0;

              return (
                <FileItem
                  {...rest}
                  key={id}
                  id={id}
                  file={originalFile}
                  onCrossClick={removeFiles}
                  onSetValuesClick={handleItemConfiguration}
                  onEditValuesClick={handleItemConfiguration}
                >
                  {showFileContent && (
                    <div className={styles.column}>
                      {data.emailIndex >= 0 && (
                        <div className={styles.additionalInfo}>
                          <span>Selected email column:</span> {data.emailIndex + 1}
                        </div>
                      )}
                    </div>
                  )}
                </FileItem>
              );
            })}
          </div>
          {editedFile && (
            <UnsubValuesModal file={editedFile} onCloseClick={closeModal} onSubmitClick={handleModalSubmit} />
          )}
        </div>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isValid }) => (
            <Form>
              <UnsubListSelect />

              <div className={styles.buttons}>
                {!isEntityFinished && (
                  <Button
                    type="submit"
                    disabled={!hasAllConfigured || !files.length || !isValid}
                    loading={!isEntityInConfiguration}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
