import { FC, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form, FormikProps } from "formik";
import {
  Button,
  MessageField,
  FormButtons,
  FormField,
  notification,
  Modal,
  Label,
  Switch,
} from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { updateInsiderAccount, UpdateInsiderAccountData } from "api";
import { InsiderAccountModel } from "models";
import { validationSchema, initialValues } from "./update-insider.constants";

import styles from "./update-insider.module.scss";

export type UpdateInsiderAccountProps = {
  isOpen: boolean;
  close: VoidFunction;
  account: InsiderAccountModel | null;
  onUpdateSuccess: (value: InsiderAccountModel) => void;
};

export const UpdateInsiderAccount: FC<UpdateInsiderAccountProps> = ({ isOpen, account, close, onUpdateSuccess }) => {
  const [clientSecretEnabled, setClientSecretEnabled] = useState<boolean>(false);

  const { submit, submitting, error, onSubmitSuccess, onSubmitError } = useSubmit(updateInsiderAccount);
  onSubmitSuccess(({ response }) => {
    onUpdateSuccess(response);
    close();
    notification.success("Insider account updated!", "Insider account has been successfully updated.");
  });
  onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the Insider account.");
  });

  useDidUpdate(() => {
    setClientSecretEnabled(false);
  }, [isOpen]);

  const handleSwitchToggle =
    (formikProps: FormikProps<Partial<UpdateInsiderAccountData>>) => (value: string, checked: boolean) => {
      setClientSecretEnabled(checked);
      if (!checked) {
        formikProps.setFieldValue("apiKey", "");
        formikProps.setFieldTouched("apiKey", false, false);
      }
    };

  const handleSubmit = async (values: UpdateInsiderAccountData) => {
    if (account) {
      const data = { ...values };
      if (!clientSecretEnabled) delete data.apiKey;

      await submit({
        data,
        params: { accountId: account.id },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik
        initialValues={initialValues(account)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(clientSecretEnabled)}
        enableReinitialize
      >
        {(props) => (
          <Form>
            <div className={styles.title}>Update {account?.name} account</div>
            <p className={styles.description}>
              If the switch is checked, a new API key will be required. When the switch is not checked, the client
              secret that was added earlier will be used.
            </p>

            <FormField type="text" name="name" label="Account name" disableFloatingLabel required />

            <div className={styles.row}>
              <FormField
                type="text"
                name="partnerName"
                label="Partner name"
                disableFloatingLabel
                required
                className={styles.clientIdField}
              />
              <div>
                <div className={styles.labelRow}>
                  <Label text="API key" isInputLabel />
                  <Switch
                    value="test"
                    disableError
                    checked={clientSecretEnabled}
                    onChange={handleSwitchToggle(props)}
                  />
                </div>
                <FormField
                  type="text"
                  name="clientSecret"
                  placeholder={clientSecretEnabled ? "Provide a new API key" : "Use the same API key"}
                  disableFloatingLabel
                  disabled={!clientSecretEnabled}
                />
              </div>
            </div>

            <MessageField message={error?.message || ""} />

            <FormButtons className={styles.buttons}>
              <Button appearance="secondary" onClick={close}>
                Cancel
              </Button>
              <Button type="submit" loading={submitting}>
                Submit
              </Button>
            </FormButtons>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
