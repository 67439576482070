import React, { useRef, useState } from "react";
import { Form, Formik } from "formik";
import { Button, FormField, notification } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useSubmit } from "@hyper-fetch/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import cn from "classnames";

import { initialValues, validationSchema } from "./suppress-manually.constants";
import { createSuppressions } from "api";
import { DASHBOARD_PAGE, JOBS_LIST_PAGE } from "constants/routes.constants";
import { SuppressManuallyFormData } from "./suppress-manually.types";
import { RootState } from "store";
import { useTour } from "hooks";
import { steps } from "./suppress-manually.tour";
import { ListsSelect } from "components";

import styles from "./suppress-manually.module.scss";

interface Props {
  handleFileClick: () => void;
}

export const SuppressManually: React.FC<Props> = ({ handleFileClick }) => {
  useTour({
    name: "suppression-page",
    steps: steps({ fileModeClick: handleFileClick }),
  });

  const navigate = useNavigate();
  const textareaRef = useRef<(HTMLInputElement & HTMLTextAreaElement) | null>(null);

  const { user } = useSelector((state: RootState) => state.auth);

  const [textareaValue, setTextareaValue] = useState<string>("");
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
  const [selectedDataExtensions, setSelectedDataExtensions] = useState<number[]>([]);
  const [selectedRemarketyAccounts, setSelectedRemarketyAccounts] = useState<number[]>([]);
  const [selectedMarketoAccounts, setSelectedMarketoAccounts] = useState<number[]>([]);
  const [selectedGreenArrowAccounts, setSelectedGreenArrowAccounts] = useState<number[]>([]);
  const [selectedInsiderAccounts, setSelectedInsiderAccounts] = useState<number[]>([]);
  const [selectedBlueshiftAccounts, setSelectedBlueshiftAccounts] = useState<number[]>([]);

  const { submit, onSubmitSuccess, onSubmitError, submitting } = useSubmit(createSuppressions);
  onSubmitSuccess(() => {
    notification.success("Success!", "Emails were added to queue.");
    navigate(user?.isAdmin ? JOBS_LIST_PAGE.path : DASHBOARD_PAGE.path);
  });
  onSubmitError(() => {
    notification.error("Error!", "Could not create a job.");
  });

  useDidUpdate(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "100px";
      const scrollHeight = textareaRef.current.scrollHeight;

      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textareaRef, textareaValue]);

  const handleSubmit = async (values: SuppressManuallyFormData) => {
    const emails = values.emails.split(/\r?\n/).filter(Boolean);
    await submit({
      data: {
        values: emails,
        projectIds: selectedProjects,
        dataExtensionIds: selectedDataExtensions,
        remarketyAccountIds: selectedRemarketyAccounts,
        marketoAccountIds: selectedMarketoAccounts,
        greenarrowAccountIds: selectedGreenArrowAccounts,
        insiderAccountIds: selectedInsiderAccounts,
        blueshiftAccountIds: selectedBlueshiftAccounts,
      },
    });
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        Paste each email on a new line (up to <strong>5,000</strong> at once).
      </h2>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnMount>
        {({ isValid }) => (
          <Form>
            <div className={styles.wrapper}>
              <FormField
                name="emails"
                type="textarea"
                label="Emails"
                forwardRef={textareaRef}
                onChange={setTextareaValue}
                inputClassName={styles.textarea}
                wrapperClassName={styles.textareaWrapper}
                className={cn(styles.textareaWrapper, "emails-textarea")}
              />

              <div>
                <ListsSelect
                  iterable={{ list: selectedProjects, set: setSelectedProjects }}
                  salesforce={{ list: selectedDataExtensions, set: setSelectedDataExtensions }}
                  remarkety={{ list: selectedRemarketyAccounts, set: setSelectedRemarketyAccounts }}
                  marketo={{ list: selectedMarketoAccounts, set: setSelectedMarketoAccounts }}
                  greenarrow={{ list: selectedGreenArrowAccounts, set: setSelectedGreenArrowAccounts }}
                  insider={{ list: selectedInsiderAccounts, set: setSelectedInsiderAccounts }}
                  blueshift={{ list: selectedBlueshiftAccounts, set: setSelectedBlueshiftAccounts }}
                />

                <div className={styles.buttons}>
                  <Button type="submit" disabled={!isValid} loading={submitting}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
