import React from "react";
import { useFetch } from "@hyper-fetch/react";
import { useParams } from "react-router-dom";

import { Container, Loader } from "components";
import { getUnsubscribeJob } from "api";
import { JobDetailsHeader } from "./job-details-header/job-details-header";
import { UnsubscribeValues } from "./unsubscribe-values/unsubscribe-values";
import { steps } from "./job-details.tour";
import { useTour } from "hooks";

import styles from "./job-details.module.scss";

export const UnsubscribeJobDetailsPage: React.FC = () => {
  useTour({
    name: "unsubscribe-job-details",
    steps,
  });

  const { jobId = "" } = useParams<{ jobId: string }>();

  const { data: job, loading, error } = useFetch(getUnsubscribeJob.setParams({ jobId: jobId || "" }));

  return (
    <>
      {error && (
        <div className={styles.errorWrapper}>
          <div>
            <h2 className={styles.title}>Could not fetch job details</h2>
            <span className={styles.description}>{error?.message}</span>
          </div>
        </div>
      )}
      {loading && <Loader />}
      {!loading && job && (
        <>
          <JobDetailsHeader job={job} loading={loading} />
          <Container className={styles.container}>
            <UnsubscribeValues job={job} />
          </Container>
        </>
      )}
    </>
  );
};
