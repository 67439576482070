import { client } from "api/client.api";
import { List, MarketoAccountModel } from "models";
import { fileUploadMiddleware } from "api/file-upload.api.middleware";
import { GetAccountsQuery } from "api/iterable/accounts";
import { DeleteResponse, ServerErrorType } from "api/api.types";
import { CreateMarketoAccountWithUpload, MarketoAccountData, UpdateMarketoAccountData } from "./marketo.types";

export const createMarketoAccount = client.createRequest<MarketoAccountModel, MarketoAccountData>()({
  method: "POST",
  endpoint: "/marketo/accounts",
});

export const createMarketoAccountWithFile = fileUploadMiddleware<undefined, CreateMarketoAccountWithUpload>()({
  method: "post",
  endpoint: "/marketo/accounts/form",
  isFormData: true,
});

export const getMarketoAccounts = client.createRequest<
  List<MarketoAccountModel>,
  null,
  ServerErrorType,
  GetAccountsQuery
>()({
  method: "GET",
  endpoint: "/marketo/accounts",
  options: { timeout: 0 },
});

export const getMarketoActiveAccounts = client.createRequest<
  MarketoAccountModel[],
  null,
  ServerErrorType,
  GetAccountsQuery
>()({
  method: "GET",
  endpoint: "/marketo/accounts/active",
  options: { timeout: 0 },
});

export const getMarketoAccount = client.createRequest<MarketoAccountModel>()({
  method: "GET",
  endpoint: "/marketo/accounts/:accountId",
  options: { timeout: 0 },
});

export const updateMarketoAccount = client.createRequest<MarketoAccountModel, UpdateMarketoAccountData>()({
  method: "PUT",
  endpoint: "/marketo/accounts/:accountId",
});

export const deleteMarketoAccount = client.createRequest<DeleteResponse>()({
  endpoint: "/marketo/accounts/:accountId",
  method: "DELETE",
});
