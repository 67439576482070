import React, { FC } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { Button, MessageField, FormField, notification, Modal, Label } from "@epcnetwork/core-ui-kit";

import { createMarketoAccount, getActiveEsps, MarketoAccountData } from "api";
import { validationSchema, initialValues } from "./create-marketo-account.constants";
import { MarketoAccountModel } from "models";

import styles from "./create-marketo-account.module.scss";

export type CreateMarketoAccountProps = {
  isOpen: boolean;
  close: VoidFunction;
  refresh: VoidFunction;
  onCreateSuccess: (value: MarketoAccountModel) => void;
};

export const CreateMarketoAccount: FC<CreateMarketoAccountProps> = ({ isOpen, close, refresh, onCreateSuccess }) => {
  const { submit, error, submitting, refetch, onSubmitSuccess, onSubmitError } = useSubmit(createMarketoAccount);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    close();
    refetch(getActiveEsps.cacheKey);
    notification.success("Marketo account created!", "Marketo account has been successfully created.");
  });
  onSubmitError(() => {
    notification.error("Create error!", "Error occurred while creating a new Marketo account.");
  });

  const handleClose = () => {
    close();
  };

  const handleSubmit = async (values: MarketoAccountData) => {
    await submit({ data: values });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isValid }) => {
          return (
            <Form>
              <div className={styles.title}>Add Marketo account</div>

              <FormField type="text" name="name" label="Account name" disableFloatingLabel required />

              <div className={styles.row}>
                <FormField type="text" name="clientId" label="Client ID" disableFloatingLabel required />
                <FormField type="text" name="clientSecret" label="Client secret" disableFloatingLabel required />
              </div>

              <div>
                <Label text="Endpoint" isInputLabel isRequired />
                <p className={styles.description}>You need to provide endpoint value in a XXX-XXX-XXX format</p>
              </div>
              <FormField type="text" name="endpoint" disableFloatingLabel required />

              <MessageField message={error?.message || ""} />

              <div className={styles.buttons}>
                <Button appearance="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!isValid} loading={submitting}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
