import { boolean, object, Schema } from "yup";

import { emailColumnValidation, headersValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { UnsubValues } from "./unsub-values.types";

export const validationSchema: Schema<UnsubValues> = object().shape({
  headerValues: object().shape({
    emailIndex: emailColumnValidation(),
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
  }),
});
