import React, { useMemo } from "react";
import { formatDate, notification, TableCell, TableRow, TextEllipsis, Loader, Tooltip } from "@epcnetwork/core-ui-kit";
import { DownloadIcon } from "lucide-react";
import { useSubmit } from "@hyper-fetch/react";

import { Calendar, Clock } from "assets";
import { ExportModel } from "models";
import { Badge, JobItemStatus } from "components";
import { downloadExportJob } from "api";

import styles from "./export-job-item.module.scss";

interface Props {
  job: ExportModel;
}

const OFFERS_TO_DISPLAY = 3;

export const ExportJobItem: React.FC<Props> = ({ job }) => {
  const { submit, onSubmitSuccess, onSubmitError, submitting } = useSubmit(
    downloadExportJob.setParams({ jobId: job.id }),
  );
  onSubmitSuccess(({ response }) => {
    const element = document.createElement("a");
    element.download = `export-job-${job.id}.csv`;
    element.href = response;
    element.click();
  });
  onSubmitError(() => {
    notification.error("Error!", "Could not generate a file URL");
  });

  const createdAtDate = job.createdAt;

  const statusMessage = useMemo(() => {
    if (job.status === "error") return job?.error;
    if (job.status === "empty") return "No matches found, output is empty.";
    return job?.error || null;
  }, [job]);

  const { itemsToDisplay, restItems } = useMemo(() => {
    const itemsToDisplay = job.offers?.slice(0, OFFERS_TO_DISPLAY);
    const restItems = job.offers?.slice(OFFERS_TO_DISPLAY);

    return { itemsToDisplay, restItems };
  }, [job]);

  return (
    <TableRow id={job.id}>
      <TableCell>{job.user}</TableCell>

      <TableCell className={styles.cellRow}>
        <div className={styles.row}>
          <Calendar />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "EEE, MMM dd, yyyy")}
          </TextEllipsis>
        </div>
        <div className={styles.row}>
          <Clock />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "HH:mm:ss")}
          </TextEllipsis>
        </div>
      </TableCell>

      <TableCell>
        <div className={styles.row}>
          {itemsToDisplay.map((item) => (
            <Badge key={item.id}>{item.name}</Badge>
          ))}
          {restItems.length > 0 && (
            <Tooltip triggerElement={<Badge>+{restItems.length}</Badge>} trigger="hover">
              {restItems.map((item) => item.name).join(",")}
            </Tooltip>
          )}
        </div>
      </TableCell>

      <TableCell>
        <JobItemStatus status={job.status} message={statusMessage} />
      </TableCell>

      <TableCell>
        {job.status === "finished" && (
          <button type="button" onClick={submit} className={styles.downloadButton}>
            {!submitting && <DownloadIcon width={16} height={16} />}
            {submitting && <Loader type="clip-loader" />}
          </button>
        )}
      </TableCell>
    </TableRow>
  );
};
