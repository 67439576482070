import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  Button,
  DropAcceptedFunc,
  FileItem,
  FileUpload,
  notification,
  Nullable,
  useFileQueue,
  isExceedByteSize,
  bytesConverter,
} from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useSelector } from "react-redux";

import { DASHBOARD_PAGE, JOBS_LIST_PAGE } from "constants/routes.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { getInitialData, getSupportedFormats } from "./suppress-from-file.utils";
import { SuppressionValuesModal } from "../suppression-values-modal/suppression-values-modal";
import { SuppressionFileItem } from "./suppress-from-file.types";
import { steps } from "./suppress-from-file.tour";
import { createSuppressionsWithFile, SuppressionData, SuppressionFileInfo } from "api";
import { RootState } from "store";
import { useTour } from "hooks";
import { ListsSelect } from "components";

import styles from "./suppress-from-file.module.scss";

export const SuppressFromFile: React.FC = () => {
  useTour({
    name: "suppress-from-file",
    steps,
  });

  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.auth);

  const [editedFile, setEditedFile] = useState<Nullable<SuppressionFileItem>>(null);
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
  const [selectedDataExtensions, setSelectedDataExtensions] = useState<number[]>([]);
  const [selectedRemarketyAccounts, setSelectedRemarketyAccounts] = useState<number[]>([]);
  const [selectedMarketoAccounts, setSelectedMarketoAccounts] = useState<number[]>([]);
  const [selectedGreenArrowAccounts, setSelectedGreenArrowAccounts] = useState<number[]>([]);
  const [selectedInsiderAccounts, setSelectedInsiderAccounts] = useState<number[]>([]);
  const [selectedBlueshiftAccounts, setSelectedBlueshiftAccounts] = useState<number[]>([]);

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitOneByOne,
    hasAllSuccessFiles,
  } = useFileQueue<SuppressionFileItem, SuppressionData>("Suppression", createSuppressionsWithFile);

  const handleDropAccept: DropAcceptedFunc = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (isExceedByteSize(file, 5e6)) {
        notification.error("This CSV is larger than 5MB.", "Please upload a smaller file.");
      } else {
        addFiles(createInitialFile(file, { data: getInitialData(file) }));
      }
    },
    [addFiles, createInitialFile],
  );

  const handleSubmit = async () => {
    const data = files.map((file) => {
      const formData = new FormData();

      const { fileName, emailIndex, hasHeaders } = file.data;
      const suppressionData: SuppressionFileInfo = {
        emailIndex,
        hasHeaders,
        projectIds: selectedProjects,
        dataExtensionIds: selectedDataExtensions,
        remarketyAccountIds: selectedRemarketyAccounts,
        marketoAccountIds: selectedMarketoAccounts,
        greenarrowAccountIds: selectedGreenArrowAccounts,
        insiderAccountIds: selectedInsiderAccounts,
        blueshiftAccountIds: selectedBlueshiftAccounts,
      };

      formData.append("form", JSON.stringify(suppressionData));
      formData.append("file", file.originalFile, fileName);

      return {
        id: file.id,
        data: formData,
      };
    });

    await submitOneByOne(data);
  };

  useDidUpdate(() => {
    const haveError = Boolean(files[0]?.error);

    if (isEntityFinished && !haveError) {
      notification.success("Success!", "Emails were added to queue.");

      removeFiles(files[0].id);
      clearEntity();

      navigate(user?.isAdmin ? JOBS_LIST_PAGE.path : DASHBOARD_PAGE.path);
    }

    if (isEntityFinished && haveError) {
      const error = files[0]?.error;
      notification.error("Error!", `Could not create a job. ${error}`);
    }
  }, [isEntityFinished]);

  const closeModal = () => setEditedFile(null);

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: SuppressionFileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleItemConfiguration = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const getAcceptedFilesText = (formats: string[]): string => `Accepted ${formats.join(", ")} files`;

  const supportedFormats = [csvFormat, txtFormat].flat();
  const MAX_FILE_SIZE = 1;

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Upload a CSV file containing emails to add to your suppression list.</h2>

      <div className={styles.form}>
        <div>
          <FileUpload
            className={classNames(styles.dropZone, { [styles.uploadZone]: files.length })}
            uploadedFilesLength={files.length}
            subtitle={`${getAcceptedFilesText(getSupportedFormats(supportedFormats))} - max ${MAX_FILE_SIZE}MB.`}
            accept={supportedFormats}
            onDropAccepted={handleDropAccept}
            exceedFilesOption="splice-with-error"
            disabled={!isEntityInConfiguration}
            preventDropOnDocument
            multiple={false}
            maxSize={bytesConverter(MAX_FILE_SIZE, "Bytes", "MB")}
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => {
              const showFileContent = data.emailIndex >= 0;

              return (
                <FileItem
                  {...rest}
                  key={id}
                  id={id}
                  file={originalFile}
                  onCrossClick={removeFiles}
                  onSetValuesClick={handleItemConfiguration}
                  onEditValuesClick={handleItemConfiguration}
                >
                  {showFileContent && (
                    <div className={styles.column}>
                      {data.emailIndex >= 0 && (
                        <div className={styles.additionalInfo}>
                          <span>Selected email column:</span> {data.emailIndex + 1}
                        </div>
                      )}
                    </div>
                  )}
                </FileItem>
              );
            })}
          </div>
          {editedFile && (
            <SuppressionValuesModal file={editedFile} onCloseClick={closeModal} onSubmitClick={handleModalSubmit} />
          )}
        </div>

        <div>
          <ListsSelect
            iterable={{ list: selectedProjects, set: setSelectedProjects }}
            salesforce={{ list: selectedDataExtensions, set: setSelectedDataExtensions }}
            remarkety={{ list: selectedRemarketyAccounts, set: setSelectedRemarketyAccounts }}
            marketo={{ list: selectedMarketoAccounts, set: setSelectedMarketoAccounts }}
            greenarrow={{ list: selectedGreenArrowAccounts, set: setSelectedGreenArrowAccounts }}
            insider={{ list: selectedInsiderAccounts, set: setSelectedInsiderAccounts }}
            blueshift={{ list: selectedBlueshiftAccounts, set: setSelectedBlueshiftAccounts }}
          />

          <div className={styles.buttons}>
            {!isEntityFinished && (
              <Button
                onClick={handleSubmit}
                disabled={!hasAllConfigured || !files.length}
                loading={!isEntityInConfiguration}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
