import React from "react";
import { useFetch, useSubmit } from "@hyper-fetch/react";
import { bytesConverter, Drawer, formatDate, Label, notification, TextEllipsis } from "@epcnetwork/core-ui-kit";
import { DownloadIcon, FileIcon } from "lucide-react";

import { AuditLogModel } from "models";
import { actions, renderTypeLabel } from "pages/audit-logs/list/audit-logs-list.constants";
import { Calendar, Clock } from "assets";
import { getAuditLog, getFileUrl } from "api";
import { Loader } from "components";

import styles from "../audit-logs-list.module.scss";

interface Props {
  action: AuditLogModel;
  isOpen: boolean;
  handleClose: () => void;
}

export const ActionDrawer: React.FC<Props> = ({ action, handleClose, isOpen }) => {
  const { data, loading, error } = useFetch(getAuditLog.setParams({ actionId: action.id }));

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(getFileUrl.setParams({ actionId: action.id }));
  onSubmitSuccess(({ response }) => {
    if (data?.fileMeta) {
      const element = document.createElement("a");
      element.download = data.fileMeta.filename;
      element.href = response;
      element.click();
    }
  });
  onSubmitError(({ response }) => {
    notification.error("Could not download the file", response?.message);
  });

  const handleFileDownload = async () => {
    await submit();
  };

  const convertBytes = (size: number) => {
    const value = bytesConverter(size, "KB");
    return value.toFixed(2);
  };

  return (
    <Drawer isOpen={isOpen} setClose={handleClose}>
      {loading && (
        <div className={styles.wrapper}>
          <Loader />
        </div>
      )}
      {(!data || error) && !loading && (
        <div className={styles.wrapper}>
          <p>Could not fetch the data</p>
        </div>
      )}
      {data && !loading && (
        <div className={styles.drawer}>
          <div className={styles.titleWrapper}>
            {actions[action.action].icon}
            <h2>{data.action.toUpperCase()}</h2>
            <span>{renderTypeLabel(data.type)}</span>
          </div>

          <div className={styles.drawerContent}>
            {data.name && <p>Name: {data.name}</p>}
            <p>User: {data.email}</p>
            <div className={styles.drawerDate}>
              <div className={styles.row}>
                <Calendar />
                <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
                  <span className={styles.description}>{formatDate(data.date, "EEE, MMM dd, yyyy")}</span>
                </TextEllipsis>
              </div>
              <div className={styles.row}>
                <Clock />
                <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
                  <span className={styles.description}>{formatDate(data.date, "HH:mm:ss")}</span>
                </TextEllipsis>
              </div>
            </div>

            {data?.fileMeta && (
              <div>
                <div className={styles.fileBox}>
                  <div className={styles.row}>
                    <FileIcon size={20} />

                    <div>
                      <TextEllipsis>
                        <span className={styles.fileName}>{data.fileMeta.filename}</span>
                      </TextEllipsis>
                      <p className={styles.fileSize}>Size: {convertBytes(data.fileMeta.size)} KB</p>
                      <p className={styles.fileSize}>Emails: {data.fileMeta.valuesCount}</p>
                    </div>
                  </div>

                  <button type="button" className={styles.download} onClick={handleFileDownload}>
                    <DownloadIcon size={16} />
                  </button>
                </div>
              </div>
            )}

            {data.prevData && (
              <div>
                <Label text="Previous data" />
                <div className={styles.codeBlock}>
                  <code>
                    <pre>{JSON.stringify(JSON.parse(data.prevData), null, 2)}</pre>
                  </code>
                </div>
              </div>
            )}

            {data.newData && (
              <div>
                <Label text={data.prevData ? "Data" : "New data"} />
                <div className={styles.codeBlock}>
                  <code>
                    <pre>{JSON.stringify(JSON.parse(data.newData), null, 2)}</pre>
                  </code>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Drawer>
  );
};
