import { client } from "api/client.api";
import { AuditLogModel, AuditLogModelWithData, List } from "models";
import { ServerErrorType } from "api/api.types";
import { AuditLogsQueryParams } from "./audit-logs.types";

export const getAuditLogs = client.createRequest<List<AuditLogModel>, null, ServerErrorType, AuditLogsQueryParams>()({
  method: "GET",
  endpoint: "/user-actions",
  options: { timeout: 0 },
});

export const getAuditLog = client.createRequest<AuditLogModelWithData, null, ServerErrorType, AuditLogsQueryParams>()({
  method: "GET",
  endpoint: "/user-actions/:actionId",
  options: { timeout: 0 },
});

export const getFileUrl = client.createRequest<string, null, ServerErrorType>()({
  method: "GET",
  endpoint: "/user-actions/:actionId/signed-url",
  options: { timeout: 0 },
});
