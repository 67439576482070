import React from "react";
import { useFetch } from "@hyper-fetch/react";
import { Button, Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";

import { Container, TableError, TableNoContent } from "components";
import { Navbar } from "components/layout/navbar/navbar";
import { useItemManagement, usePermissions, useSidebar, useTablePagination, useTour } from "hooks";
import { MarketoAccountModel, RemarketyAccountModel } from "models";
import { getMarketoAccounts, getRemarketyAccounts } from "api";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { addItem, getInitialStorageFilters, removeItem, updateItem } from "utils";
import { filtersOptions, TABLE_NAME, marketoAccountsColumns } from "./marketo-accounts.constants";
import { DeleteResponse } from "api/api.types";
import { MarketoAccountItem } from "./table-item/marketo-account-item";
import { steps } from "./marketo-accounts.tour";
import { Plus } from "assets";
import { CreateMarketoAccount } from "./create/create-marketo-account";
import { UpdateMarketoAccount } from "./update/update-marketo";

import styles from "./marketo-accounts.module.scss";

export const MarketoAccountsPage: React.FC = () => {
  useTour({
    name: "marketo-accounts",
    steps,
  });

  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();
  const { create: createAccount } = useItemManagement<MarketoAccountModel>();
  const { update } = useItemManagement<MarketoAccountModel>();
  const { query, searchValue, setSearch } = useFilters<BaseSearchQuery & BasePaginationQuery>(
    getInitialStorageFilters<BaseSearchQuery & BasePaginationQuery>("marketo", filtersOptions),
  );

  const [canCreateAccount] = usePermissions("create", "marketo-accounts");

  const { data, loading, refetch, error, setData } = useFetch(
    getMarketoAccounts.setQueryParams({ ...query, search: query.search }),
  );

  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const handleEdit = (account: MarketoAccountModel) => {
    update.setItem(account);
  };

  const handleCreateAccountSuccess = (account: MarketoAccountModel) => {
    if (data) addItem(account, data, setData);
  };

  const handleUpdateAccountSuccess = (account: MarketoAccountModel) => {
    if (data) updateItem(account, data, setData);
  };

  const handleRemoveSuccess = (response: DeleteResponse) => {
    if (data) removeItem(response, data, setData);
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  return (
    <>
      <Navbar
        isMenuCollapsed={isMenuCollapsed}
        toggleSidebar={toggleSidebar}
        name="Marketo accounts"
        description="List of all Marketo accounts."
        navbarClassName="marketo-navbar"
      />
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />
            {canCreateAccount && (
              <Button onClick={createAccount.open} className="add-remarkety-account">
                <Plus /> Add Marketo account
              </Button>
            )}
          </div>

          <Table
            entityName={TABLE_NAME}
            columns={marketoAccountsColumns}
            list={data?.data}
            error={error?.message}
            refresh={() => refetch()}
            loading={loading}
            resetColumnsOnMount={false}
            isTabTable
            customNoContent={<TableNoContent withBackground />}
            customError={<TableError description={error?.message} withBackground />}
            pagination={{
              ...pagination,
              elementsPerPage: currentElementsPerPage,
              onElementsPerPageChange: handlePerPageChange,
            }}
            row={(account, index) => (
              <MarketoAccountItem
                index={index}
                account={account}
                handleEdit={handleEdit}
                onRemoveSuccess={handleRemoveSuccess}
                onActivateSuccess={handleUpdateAccountSuccess}
              />
            )}
          />
          <CreateMarketoAccount
            isOpen={createAccount.isOpen}
            close={createAccount.close}
            refresh={refetch}
            onCreateSuccess={handleCreateAccountSuccess}
          />
          <UpdateMarketoAccount
            account={update.item}
            isOpen={update.isOpen}
            close={update.close}
            onUpdateSuccess={handleUpdateAccountSuccess}
          />
        </div>
      </Container>
    </>
  );
};
