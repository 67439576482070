import { object, string, Schema } from "yup";

import { UpdateMarketoAccountData } from "api";
import { MarketoAccountModel } from "models";
import { MARKETO_ENDPOINT_REGEX } from "constants/regex.constants";

export const validationSchema = (clientSecretEnabled: boolean): Schema<UpdateMarketoAccountData> =>
  object().shape({
    name: string().required("Name is required"),
    clientId: string().required("Client ID is required"),
    endpoint: string()
      .matches(MARKETO_ENDPOINT_REGEX, "Endpoint must be in a XXX-XXX-XXX format")
      .required("Endpoint is required"),
    clientSecret: clientSecretEnabled ? string().required("Client secret is required") : string(),
  });

export const initialValues = (account: MarketoAccountModel | null): UpdateMarketoAccountData => ({
  name: account?.name || "",
  clientId: account?.clientId || "",
  clientSecret: "",
  endpoint: account?.endpoint || "",
});
