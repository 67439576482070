import React, { useRef } from "react";
import { Form, Formik, FormikProps } from "formik";
import { useSubmit } from "@hyper-fetch/react";
import { notification } from "@epcnetwork/core-ui-kit";
import { useNavigate } from "react-router-dom";

import { Container } from "components";
import { OptizmoSelector } from "./optizmo-selector/optizmo-selector";
import { ExportData } from "./export.types";
import { initialValues, validationSchema } from "./export.constants";
import { createExport } from "api";
import { EXPORT_JOBS_PAGE } from "constants/routes.constants";
import { useTour } from "hooks";
import { steps } from "./export.tour";

import styles from "./export.module.scss";

export const ExportPage: React.FC = () => {
  useTour({
    name: "md5-export",
    steps,
  });

  const formikRef = useRef<FormikProps<ExportData> | null>(null);
  const navigate = useNavigate();

  const { submit, submitting, onSubmitSuccess, onSubmitError } = useSubmit(createExport);
  onSubmitSuccess(() => {
    notification.success("Success!", "Export created.");
    navigate(EXPORT_JOBS_PAGE.path);
  });
  onSubmitError(({ response }) => {
    const message = response?.statusCode === 400 ? response?.message : "Could not create an export.";

    notification.error("Error!", message);
  });

  const handleSubmit = async ({ offerIds }: ExportData) => {
    await submit({ data: { offerIds } });
  };

  return (
    <Container>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form>
          <div className={styles.wrapper}>
            <OptizmoSelector submitting={submitting} />
          </div>
        </Form>
      </Formik>
    </Container>
  );
};
