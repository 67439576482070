import { client } from "api/client.api";
import { ServerErrorType } from "api/api.types";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { List, UnsubscribeJobModel, UnsubscribeValueModel } from "models";

export const getUnsubscribeJobs = client.createRequest<
  List<UnsubscribeJobModel>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery
>()({
  method: "GET",
  endpoint: "/unsubscribe/jobs",
  options: { timeout: 0 },
});

export const getUnsubscribeJob = client.createRequest<UnsubscribeJobModel>()({
  method: "GET",
  endpoint: "/unsubscribe/jobs/:jobId",
  options: { timeout: 0 },
});

export const getUnsubscribeJobValues = client.createRequest<
  List<UnsubscribeValueModel>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery & { "order[value]": string }
>()({
  method: "GET",
  endpoint: "/unsubscribe/jobs/:jobId/emails",
  options: { timeout: 0 },
});
