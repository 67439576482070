import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { BasePaginationQuery, BaseSearchQuery, ColumnsType } from "types";
import { SEARCH_MIN_LENGTH } from "constants/form.constants";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "constants/query.constants";

export const filtersOptions: UseFiltersProps<BaseSearchQuery & BasePaginationQuery> = {
  searchOptions: { searchKey: "search", searchMinLength: SEARCH_MIN_LENGTH },
  initialState: {
    search: "",
    limit: DEFAULT_LIMIT,
    offset: DEFAULT_OFFSET,
  },
};

export const blueshiftAccountsColumns: ColumnsType = [
  { label: "Name", required: true, queryKey: "name" },
  { label: "Active" },
  { label: "" },
];

export const TABLE_NAME = "blueshift-table";
