import { client } from "api/client.api";
import { GreenArrowAccountModel, List } from "models";
import { DeleteResponse, ServerErrorType } from "api/api.types";
import {
  GreenArrowAccountData,
  UpdateGreenArrowAccountData,
  GetGreenArrowAccountsQuery,
  GreenArrowList,
  GetSuppressionListsData,
} from "./green-arrow.types";

export const createGreenArrowAccount = client.createRequest<GreenArrowAccountModel, GreenArrowAccountData>()({
  method: "POST",
  endpoint: "/greenarrow/accounts",
});

export const getGreenArrowSuppressionListsWithCredentials = client.createRequest<
  GreenArrowList[],
  GetSuppressionListsData
>()({
  method: "POST",
  endpoint: "/greenarrow/suppression-lists",
});

export const getGreenArrowAccounts = client.createRequest<
  List<GreenArrowAccountModel>,
  null,
  ServerErrorType,
  GetGreenArrowAccountsQuery
>()({
  method: "GET",
  endpoint: "/greenarrow/accounts",
  options: { timeout: 0 },
});

export const getGreenArrowActiveAccounts = client.createRequest<
  GreenArrowAccountModel[],
  null,
  ServerErrorType,
  GetGreenArrowAccountsQuery
>()({
  method: "GET",
  endpoint: "/greenarrow/accounts/active",
  options: { timeout: 0 },
});

export const getGreenArrowAccount = client.createRequest<GreenArrowAccountModel>()({
  method: "GET",
  endpoint: "/greenarrow/accounts/:accountId",
  options: { timeout: 0 },
});

export const getGreenArrowMailingLists = client.createRequest<GreenArrowList[]>()({
  method: "GET",
  endpoint: "/greenarrow/accounts/:accountId/mailing-lists",
  options: { timeout: 0 },
});

export const getGreenArrowSuppressionLists = client.createRequest<GreenArrowList[]>()({
  method: "GET",
  endpoint: "/greenarrow/accounts/:accountId/suppression-lists",
  options: { timeout: 0 },
});

export const updateGreenArrowAccount = client.createRequest<GreenArrowAccountModel, UpdateGreenArrowAccountData>()({
  method: "PUT",
  endpoint: "/greenarrow/accounts/:accountId",
});

export const deleteGreenArrowAccount = client.createRequest<DeleteResponse>()({
  endpoint: "/greenarrow/accounts/:accountId",
  method: "DELETE",
});
