import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Unsubscribe page</h2>
    <p>
      The unsubscribe page allows users to manually add emails or upload a file to unsubscribe contacts across selected
      lists from selected ESP&apos;s.
    </p>
    <p>Simply copy and paste a list of email addresses into the provided field for quick processing.</p>
    <p>Choose from supported Email Service Providers (ESPs) like Iterable, Salesforce, and more.</p>
    <p>Select specific list within the ESP to precisely manage where these email addresses will be unsubscribed.</p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    permission: ["create", "unsub"],
  },
];
