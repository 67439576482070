import {
  Role,
  BaseSubjects,
  BaseActions,
  SuppressionSubject,
  SuppressionActions,
  JobSubject,
  JobsActions,
  DashboardSubject,
  DashboardActions,
  AuditLogsSubject,
  AuditLogsActions,
  OptizmoExportSubject,
  OptizmoExportActions,
  Md5Actions,
  Md5Subject,
  UnsubSubject,
  UnsubActions,
  UnsubJobsSubject,
  UnsubJobsActions,
  AutocleaningLogsSubject,
  AutocleaningLogsActions,
} from "models";

type BasePermissionType = {
  [key in Role]: { [subject in BaseSubjects]: { [action in BaseActions]: boolean } };
};

type SuppressionPermissionType = {
  [key in Role]: { [subject in SuppressionSubject]: { [action in SuppressionActions]: boolean } };
};

type JobPermissionType = {
  [key in Role]: { [subject in JobSubject]: { [action in JobsActions]: boolean } };
};

type DashboardPermissionType = {
  [key in Role]: { [subject in DashboardSubject]: { [action in DashboardActions]: boolean } };
};

type AuditLogsPermissionType = {
  [key in Role]: { [subject in AuditLogsSubject]: { [action in AuditLogsActions]: boolean } };
};

type OptizmoExportPermissionType = {
  [key in Role]: { [subject in OptizmoExportSubject]: { [action in OptizmoExportActions]: boolean } };
};

type Md5JobsPermissionType = {
  [key in Role]: { [subject in Md5Subject]: { [action in Md5Actions]: boolean } };
};

type UnsubPermissionType = {
  [key in Role]: { [subject in UnsubSubject]: { [action in UnsubActions]: boolean } };
};

type UnsubJobsPermissionType = {
  [key in Role]: { [subject in UnsubJobsSubject]: { [action in UnsubJobsActions]: boolean } };
};

// type RemarketyAccountsPermissionType = {
//   [key in Role]: { [subject in BaseSubjects]: { [action in BaseActions]: boolean } };
// };

type AutocleaningPermissionType = {
  [key in Role]: { [subject in AutocleaningLogsSubject]: { [action in AutocleaningLogsActions]: boolean } };
};

export const PERMISSIONS:
  | BasePermissionType
  | SuppressionPermissionType
  | JobPermissionType
  | DashboardPermissionType
  | OptizmoExportPermissionType
  | Md5JobsPermissionType
  | UnsubPermissionType
  | AuditLogsPermissionType
  | UnsubJobsPermissionType
  // | RemarketyAccountsPermissionType
  | AutocleaningPermissionType = {
  admin: {
    dashboard: {
      read: true,
    },
    integrations: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    projects: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    suppressions: {
      create: true,
    },
    md5: {
      create: true,
      read: true,
    },
    export: {
      create: true,
    },
    users: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    accounts: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    jobs: {
      read: true,
      terminate: true,
    },
    "audit-logs": {
      read: true,
    },
    unsub: {
      create: true,
    },
    "unsub-jobs": {
      read: true,
      terminate: true,
    },
    "remarkety-accounts": {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    "marketo-accounts": {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    "green-arrow-accounts": {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    "insider-accounts": {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    "blueshift-accounts": {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    autocleaning: {
      read: true,
    },
  },
  user: {
    dashboard: {
      read: true,
    },
    integrations: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    projects: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    suppressions: {
      create: true,
    },
    md5: {
      create: true,
      read: true,
    },
    export: {
      create: true,
    },
    users: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    accounts: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    jobs: {
      read: true,
      terminate: false,
    },
    "audit-logs": {
      read: false,
    },
    unsub: {
      create: true,
    },
    "unsub-jobs": {
      read: true,
      terminate: false,
    },
    "remarkety-accounts": {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    "marketo-accounts": {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    "green-arrow-accounts": {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    "insider-accounts": {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    "blueshift-accounts": {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    autocleaning: {
      read: false,
    },
  },
};
