import { FC, useRef, useState } from "react";
import {
  TableRow,
  TableCell,
  notification,
  TextEllipsis,
  Button,
  formatDate,
  getLinkPath,
  useQuery,
} from "@epcnetwork/core-ui-kit";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { intervalToDuration } from "date-fns";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { Progress, JobItemStatus } from "components";
import { usePermissions } from "hooks";
import { terminateJob } from "api";
import { JobModel, JobStatus } from "models";
import { JOB_DETAILS_PAGE } from "constants/routes.constants";
import { RootState } from "store";

import { Calendar, Clock, CrossCircle } from "assets/icons";

import styles from "./job-item.module.scss";

type UsersListTableRowProps = {
  job: JobModel;
  onTerminateSuccess: (response: JobModel) => void;
};

export const JobItem: FC<UsersListTableRowProps> = ({ job, onTerminateSuccess }) => {
  const navigate = useNavigate();
  const terminateButtonRef = useRef<HTMLDivElement>(null);

  const { query, stringify } = useQuery();
  const { user } = useSelector((state: RootState) => state.auth);

  const [duration, setDuration] = useState<string>("");
  const [canTerminateJob] = usePermissions(["terminate"], "jobs");

  const handleTerminateJob = () => {
    notification.confirm("Do you want to terminate this job?", "All unsuppressed items will be removed", {
      onOk: async () => {
        const { data, error } = await terminateJob.send({ params: { jobId: job.id } });

        if (error) {
          notification.error("Terminate error", "Cannot terminate this job.");
          return;
        }

        if (data) {
          onTerminateSuccess(data);
          notification.success("Job terminated", "Job successfully terminated.");
        }
      },
    });
  };

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const excludeElements = [terminateButtonRef.current];

    const containsExcluded = excludeElements.some((element) => element?.contains(e.target as HTMLElement));
    const isExcluded = excludeElements.some((element) => element === e.target);
    if (!containsExcluded && !isExcluded) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const path = getLinkPath(JOB_DETAILS_PAGE.path, { jobId: job.id });
      // set navigate state to allow going back to with the same query params
      navigate(path, { state: { queryStringified: stringify(query) } });
    }
  };

  const withZeroPrefix = (value: number | undefined) => value?.toString().padStart(2, "0");

  useDidUpdate(
    () => {
      let timerInterval: NodeJS.Timer;

      const completedStatuses: JobStatus[] = ["finished", "aborted", "failed", "warning"];

      if (!job?.startedAt && completedStatuses.includes(job.status)) {
        setDuration("");
        return;
      }
      if (!job?.startedAt) setDuration("Not started");

      if (job?.startedAt && !job?.finishedAt) {
        timerInterval = setInterval(() => {
          const startDate = new Date(job.startedAt);
          const { days, hours, minutes, seconds } = intervalToDuration({ start: startDate, end: new Date() });

          setDuration(
            `${withZeroPrefix(days)}:${withZeroPrefix(hours)}:${withZeroPrefix(minutes)}:${withZeroPrefix(seconds)}`,
          );
        }, 1000);
      }

      if (job?.startedAt && job?.finishedAt) {
        const startDate = new Date(job.startedAt);
        const endDate = new Date(job.finishedAt);
        const { days, hours, minutes, seconds } = intervalToDuration({ start: startDate, end: endDate });

        setDuration(
          `Completed in ${withZeroPrefix(days)}:${withZeroPrefix(hours)}:${withZeroPrefix(minutes)}:${withZeroPrefix(seconds)}`,
        );
      }

      return () => {
        if (timerInterval) clearInterval(timerInterval);
      };
    },
    [job?.startedAt, job?.finishedAt],
    true,
  );

  const createdAtDate = new Date(job.createdAt);
  const abortStatus: JobStatus[] = ["inProgress", "created"];

  return (
    <TableRow id={job.id} onRowClick={handleRowClick} className={styles.tableRow}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {job.email}
        </TextEllipsis>
      </TableCell>

      <TableCell className={styles.cellRow}>
        <div className={styles.row}>
          <Calendar />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "EEE, MMM dd, yyyy")}
          </TextEllipsis>
        </div>
        <div className={styles.row}>
          <Clock />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "HH:mm:ss")}
          </TextEllipsis>
        </div>
      </TableCell>

      <TableCell>
        {job.status === "inProgress" && job?.finishedCount ? (
          <Progress current={job.finishedCount} total={job.valuesCount} />
        ) : (
          <JobItemStatus status={job.status} valuesCount={job.valuesCount} successCount={job?.finishedCount} />
        )}
      </TableCell>

      <TableCell>{duration}</TableCell>

      <TableCell>
        {(canTerminateJob || job.userId === user?.id) && abortStatus.includes(job.status) && (
          <div ref={terminateButtonRef}>
            <Button
              btnSize="small"
              onClick={handleTerminateJob}
              appearance="primary"
              className={styles.terminateButton}
            >
              <CrossCircle />
            </Button>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};
