import { object, string, Schema } from "yup";

import { UpdateInsiderAccountData } from "api";
import { InsiderAccountModel } from "models";

export const validationSchema = (clientSecretEnabled: boolean): Schema<UpdateInsiderAccountData> =>
  object().shape({
    name: string().required("Name is required"),
    partnerName: string().required("Partner name is required"),
    apiKey: clientSecretEnabled ? string().required("API key is required") : string(),
  });

export const initialValues = (account: InsiderAccountModel | null): UpdateInsiderAccountData => ({
  name: account?.name || "",
  partnerName: account?.partnerName || "",
  apiKey: "",
});
