import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Optizmo export page</h2>
    <p>
      The Export page is designed to help you create and manage exports, enabling you to export email MD5s as needed.
      Once configured, a job will be created and processed, with the exported file available for download later.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Select an Optizmo offers</h2>
    <p>
      Start by selecting the offers you want to export by choosing a specific companies and clients, followed by the
      associated offers.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".md5-export-navbar", on: "bottom-start" },
    permission: ["create", "export"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".offer-selector", on: "top" },
    permission: ["create", "export"],
  },
];
