import { client } from "api/client.api";
import {
  CreateRemarketyAccountWithUpload,
  RemarketyAccountData,
  UpdateRemarketyAccountData,
} from "api/remarkety/remarkety.types";
import { List, RemarketyAccountModel } from "models";
import { fileUploadMiddleware } from "api/file-upload.api.middleware";
import { GetAccountsQuery } from "api/iterable/accounts";
import { DeleteResponse, ServerErrorType } from "api/api.types";

export const createRemarketyAccount = client.createRequest<RemarketyAccountModel, RemarketyAccountData>()({
  method: "POST",
  endpoint: "/remarkety/accounts",
});

export const createRemarketyAccountWithFile = fileUploadMiddleware<undefined, CreateRemarketyAccountWithUpload>()({
  method: "post",
  endpoint: "/remarkety/accounts/form",
  isFormData: true,
});

export const getRemarketyAccounts = client.createRequest<
  List<RemarketyAccountModel>,
  null,
  ServerErrorType,
  GetAccountsQuery
>()({
  method: "GET",
  endpoint: "/remarkety/accounts",
  options: { timeout: 0 },
});

export const getRemarketyActiveAccounts = client.createRequest<
  RemarketyAccountModel[],
  null,
  ServerErrorType,
  GetAccountsQuery
>()({
  method: "GET",
  endpoint: "/remarkety/accounts/active",
  options: { timeout: 0 },
});

export const getRemarketyAccount = client.createRequest<RemarketyAccountModel>()({
  method: "GET",
  endpoint: "/remarkety/accounts/:accountId",
  options: { timeout: 0 },
});

export const updateRemarketyAccount = client.createRequest<RemarketyAccountModel, UpdateRemarketyAccountData>()({
  method: "PUT",
  endpoint: "/remarkety/accounts/:accountId",
});

export const deleteRemarketyAccount = client.createRequest<DeleteResponse>()({
  endpoint: "/remarkety/accounts/:accountId",
  method: "DELETE",
});
