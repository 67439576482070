import { ColumnsType } from "types";

export const TABLE_NAME = "values-by-data-extensions";

export const dataExtensionColumns: ColumnsType = [
  { label: "Data extension", required: true, queryKey: "name" },
  { label: "Integration" },
  { label: "Business unit", queryKey: "businessUnit" },
  { label: "Success", required: true, queryKey: "success" },
  { label: "Failed", required: true, queryKey: "failed" },
  { label: "In progress", required: true, queryKey: "inProgress" },
];
