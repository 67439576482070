import React, { useState } from "react";
import { useDidMount } from "@better-hooks/lifecycle";
import { Label, Select, SelectOption } from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";

import { getActiveClients, getActiveCompanies, getActiveOffers } from "api";

import styles from "./offer-selector.module.scss";

interface Props {
  offerId: number | null;
  onOfferChange: (value: number | null) => void;
}

export const OfferSelector: React.FC<Props> = ({ offerId, onOfferChange }) => {
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [clientId, setClientId] = useState<number | null>(null);

  const [companiesOptions, setCompaniesOptions] = useState<SelectOption<number>[]>([]);
  const [clientsOptions, setClientsOptions] = useState<SelectOption<number>[]>([]);
  const [offersOptions, setOffersOptions] = useState<SelectOption<number>[]>([]);

  const activeCompanies = useSubmit(getActiveCompanies);
  activeCompanies.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((company) => ({
      label: company.name,
      value: company.id,
    }));
    setCompaniesOptions(options);
  });

  const activeClients = useSubmit(getActiveClients);
  activeClients.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((client) => ({
      label: client.name,
      value: client.id,
    }));

    if (options.length) handleClientChange(options[0]);
    setClientsOptions(options);
  });

  const activeOffers = useSubmit(getActiveOffers);
  activeOffers.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((offer) => ({
      label: offer.name,
      value: offer.id,
    }));

    if (options.length) onOfferChange(options[0].value);
    setOffersOptions(options);
  });

  useDidMount(() => {
    activeCompanies.submit();
  });

  const handleCompanyChange = (option: SelectOption<number>) => {
    setCompanyId(option?.value || null);
    setClientId(null);
    onOfferChange(0);

    if (option?.value) {
      activeClients.submit({ queryParams: { companyId: option.value.toString() } });
    }
  };

  const handleClientChange = (option: SelectOption<number>) => {
    setClientId(option?.value || null);
    onOfferChange(null);

    if (option?.value) {
      activeOffers.submit({ queryParams: { clientId: option.value.toString(), optizmo: "false" } });
    }
  };

  return (
    <div className={`${styles.container} offer-selector`}>
      <div>
        <div className={styles.row}>
          <div className={styles.step}>1</div>
          <div>
            <Label text="Company" isInputLabel />
            <p className={styles.projectDescription}>Select a company</p>
          </div>
        </div>
        <Select
          name="companyId"
          options={companiesOptions}
          isSearchable
          selectedOptionsKeys={companyId || undefined}
          onChange={handleCompanyChange}
          asyncOptions={{
            loading: activeCompanies.submitting,
          }}
          searchPlaceholder="Search projects"
          isMulti={false}
        />
      </div>

      <div>
        <div className={styles.row}>
          <div className={styles.step}>2</div>
          <div>
            <Label text="Clients" isInputLabel />
            <p className={styles.projectDescription}>Select a client</p>
          </div>
        </div>
        <Select
          name="clientId"
          options={clientsOptions}
          isSearchable
          selectedOptionsKeys={clientId || undefined}
          onChange={handleClientChange}
          searchPlaceholder="Search clients"
          disabled={!companyId || activeCompanies.submitting}
          asyncOptions={{
            loading: activeClients.submitting,
          }}
          isMulti={false}
        />
      </div>

      <div>
        <div className={styles.row}>
          <div className={styles.step}>3</div>
          <div>
            <Label text="Offer" isInputLabel />
            <p className={styles.projectDescription}>Select an offer</p>
          </div>
        </div>
        <Select
          name="offerId"
          options={offersOptions}
          isSearchable
          selectedOptionsKeys={offerId || undefined}
          onChange={(option) => onOfferChange(option?.value || null)}
          asyncOptions={{
            loading: activeOffers.submitting,
          }}
          disabled={!clientId || activeCompanies.submitting || activeClients.submitting}
          searchPlaceholder="Search offers"
          isMulti={false}
        />
      </div>
    </div>
  );
};
