import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { isNumber } from "@epcnetwork/core-ui-kit";

import "./counter.scss";
import styles from "../info-box/info-box.module.scss";

const usePrevious = (value: number) => {
  const ref = useRef<number>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const formatForDisplay = (number: number, includeDecimals: boolean) => {
  if (isNaN(number)) return [];
  return parseFloat(Math.max(number, 0).toString())
    .toFixed(includeDecimals ? 2 : 0)
    .split("")
    .reverse();
};

const DecimalColumn = ({ fontSize }: { fontSize: string }) => {
  return (
    <div>
      <span
        style={{
          fontSize: fontSize,
          lineHeight: fontSize,
          color: "#fff",
        }}
      >
        .
      </span>
    </div>
  );
};

type NumberColumnProps = {
  digit: number;
  delta: string;
  fontSize: string;
  color?: string;
  includeDecimals?: boolean;
  testId?: string;
};

const NumberColumn = ({ digit, delta, fontSize, color, includeDecimals, testId }: NumberColumnProps) => {
  const [position, setPosition] = useState(0);
  const [animationClass, setAnimationClass] = useState<string | null>(null);
  const previousDigit = usePrevious(digit);
  const columnContainer = useRef<HTMLDivElement>(null);

  const setColumnToNumber = (value: number) => {
    if (columnContainer.current) {
      setPosition(columnContainer.current.clientHeight * parseInt(value.toString(), 10));
    }
  };

  useEffect(() => setAnimationClass(previousDigit !== digit ? delta : ""), [digit, delta]);

  useEffect(() => setColumnToNumber(digit), [digit]);

  return (
    <div
      className="ticker-column-container"
      ref={columnContainer}
      style={{
        fontSize: fontSize,
        lineHeight: fontSize,
        color: color,
        height: "auto",
      }}
    >
      <motion.div
        animate={{ x: 0, y: position }}
        className={`ticker-column ${animationClass}`}
        onAnimationComplete={() => setAnimationClass("")}
      >
        {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((num) => {
          return (
            <div key={num} className="ticker-digit">
              <h2 className={styles.title} data-testid={testId}>
                {num}
              </h2>
            </div>
          );
        })}
      </motion.div>
      <span className="number-placeholder">0</span>
    </div>
  );
};

type AnimatedCounterProps = {
  value: number;
  includeDecimals?: boolean;
  testId?: string;
};

// Counter component
export const AnimatedCounter = ({ value = 0, includeDecimals = false, testId }: AnimatedCounterProps) => {
  const numArray = formatForDisplay(value, includeDecimals);
  const previousNumber = usePrevious(value);

  let delta: "increase" | "decrease" | null = null;
  if (previousNumber && value > previousNumber) delta = "increase";
  if (previousNumber && value < previousNumber) delta = "decrease";

  const isValidNumber = isNumber(value);

  return (
    <motion.div layout className="ticker-view">
      {!isValidNumber && <h2 className={styles.title}>-</h2>}
      {isValidNumber &&
        numArray.map((number, index) =>
          number === "." ? (
            <DecimalColumn key={index} fontSize="24px" />
          ) : (
            <NumberColumn
              key={index}
              digit={+number}
              delta={delta || ""}
              fontSize="24px"
              includeDecimals={includeDecimals}
              testId={testId}
            />
          ),
        )}
    </motion.div>
  );
};
