import { client } from "api/client.api";
import { InsiderAccountModel, List } from "models";
import { DeleteResponse, ServerErrorType } from "api/api.types";
import { InsiderAccountData, UpdateInsiderAccountData, GetInsiderAccountsQuery } from "./insider.types";

export const createInsiderAccount = client.createRequest<InsiderAccountModel, InsiderAccountData>()({
  method: "POST",
  endpoint: "/insider/accounts",
});

export const getInsiderAccounts = client.createRequest<
  List<InsiderAccountModel>,
  null,
  ServerErrorType,
  GetInsiderAccountsQuery
>()({
  method: "GET",
  endpoint: "/insider/accounts",
  options: { timeout: 0 },
});

export const getInsiderActiveAccounts = client.createRequest<
  InsiderAccountModel[],
  null,
  ServerErrorType,
  GetInsiderAccountsQuery
>()({
  method: "GET",
  endpoint: "/insider/accounts/active",
  options: { timeout: 0 },
});

export const getInsiderAccount = client.createRequest<InsiderAccountModel>()({
  method: "GET",
  endpoint: "/insider/accounts/:accountId",
  options: { timeout: 0 },
});

export const updateInsiderAccount = client.createRequest<InsiderAccountModel, UpdateInsiderAccountData>()({
  method: "PUT",
  endpoint: "/insider/accounts/:accountId",
});

export const deleteInsiderAccount = client.createRequest<DeleteResponse>()({
  endpoint: "/insider/accounts/:accountId",
  method: "DELETE",
});
