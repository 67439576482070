import React, { useState } from "react";
import { useFetch } from "@hyper-fetch/react";
import {
  Search,
  Table,
  useFilters,
  usePagination,
  DateInput,
  DateRangeFormat,
  Select,
  SelectOption,
} from "@epcnetwork/core-ui-kit";

import { Container, TableError, TableNoContent, Tabs } from "components";
import { initialFilters, auditLogsColumns, TABLE_NAME, typeOptions, actionOptions } from "./audit-logs-list.constants";
import { AuditLogsItem } from "./table-item/audit-logs-item";
import { AuditAction, AuditLogModel, AuditType } from "models";
import { AuditLogsQueryParams, getAuditLogs } from "api";
import { useTablePagination, useTour } from "hooks";
import { getInitialStorageFilters } from "utils";
import { steps } from "./audit-logs-list.tour";
import { ActionDrawer } from "./action-drawer/action-drawer";

import styles from "./audit-logs-list.module.scss";

export const AuditLogsListPage: React.FC = () => {
  useTour({
    name: "audit-logs",
    steps,
  });

  const { query, searchValue, setSearch, setValue, setQueryParams } = useFilters<AuditLogsQueryParams>(
    getInitialStorageFilters<AuditLogsQueryParams>(TABLE_NAME, initialFilters),
  );

  const [openedAction, setOpenedAction] = useState<AuditLogModel | null>(null);
  const [period, setPeriod] = useState<string>("all");

  const { data, loading, refetch, error } = useFetch(getAuditLogs.setQueryParams(query));

  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const handlePeriodChange = (option: string) => {
    setPeriod(option);

    const subtractFromDate = (days: number | null) => {
      if (!days) {
        setValue("fromDate")("");
        return;
      }
      const currentDate = new Date();
      const date = new Date(currentDate.setDate(currentDate.getDate() - days));
      setValue("fromDate")(date.toISOString());
    };

    if (option === "1d") subtractFromDate(1);
    if (option === "7d") subtractFromDate(7);
    if (option === "1m") subtractFromDate(30);
    if (option === "1y") subtractFromDate(365);
    if (option === "all") subtractFromDate(null);
  };

  const handleDateChange = (date: DateRangeFormat) => {
    if (date[0] && !isNaN(new Date(date[0]).getTime())) setValue("fromDate")(date[0].toISOString());
    if (date[1] && !isNaN(new Date(date[1]).getTime())) setValue("toDate")(date[1].toISOString());
  };

  const handleActionChange = (value: SelectOption<AuditAction | ""> | null) => {
    if (value) {
      setQueryParams({ ...query, limit: "50", offset: "0", search: "", action: value.value });
    }
  };

  const handleTypeChange = (value: SelectOption<AuditType | ""> | null) => {
    if (value) {
      setQueryParams({ ...query, limit: "50", offset: "0", search: "", type: value.value });
    }
  };

  const handleItemPreview = (item: AuditLogModel | null) => {
    if (item) setOpenedAction(item);
    if (!item) setOpenedAction(null);
  };

  return (
    <Container>
      <div className={styles.header}>
        <div>
          <Search searchValue={searchValue} setSearch={setSearch} />
        </div>
        <Tabs
          selected={period}
          className={styles.tabs}
          onChange={(tab) => handlePeriodChange(tab.value)}
          options={[
            { label: "1d", value: "1d", className: styles.tabSmallButton },
            { label: "7d", value: "7d", className: styles.tabSmallButton },
            { label: "1m", value: "1m", className: styles.tabSmallButton },
            { label: "1y", value: "1y", className: styles.tabSmallButton },
            { label: "All time", value: "all", className: styles.tabButton },
            { label: "Custom", value: "custom", className: styles.tabButton },
          ]}
        />
      </div>

      <div className={styles.selectsWrapper}>
        <div className={styles.row}>
          <Select
            options={actionOptions}
            selectedOptionsKeys={query?.action || ""}
            onChange={handleActionChange}
            inputSize="small"
            label="Action"
            disableClearing
            sortOptions={false}
            className={styles.select}
            data-testid="audit-action-select"
          />

          <Select
            options={typeOptions}
            selectedOptionsKeys={query?.type || ""}
            onChange={handleTypeChange}
            inputSize="small"
            label="Type"
            disableClearing
            className={styles.select}
            data-testid="audit-type-select"
          />
        </div>

        {period === "custom" && (
          <div className={styles.dateRange}>
            <DateInput
              onChange={handleDateChange}
              isMulti
              className={styles.dateRangeInput}
              dateFrom={query?.fromDate ? new Date(query.fromDate) : null}
              dateTo={query?.toDate ? new Date(query.toDate) : null}
              minTo={query?.fromDate ? new Date(query.fromDate) : null}
              maxTo={new Date()}
              labels={["From", "To"]}
            />
          </div>
        )}
      </div>

      <Table
        entityName={TABLE_NAME}
        columns={auditLogsColumns}
        list={data?.data || []}
        error={error?.message}
        refresh={() => refetch()}
        loading={loading}
        resetColumnsOnMount={false}
        customNoContent={<TableNoContent withBackground />}
        customError={<TableError description={error?.message} withBackground />}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
        row={(auditLog) => <AuditLogsItem auditLog={auditLog} onItemClick={handleItemPreview} />}
      />

      {Boolean(openedAction) && openedAction && (
        <ActionDrawer action={openedAction} isOpen={Boolean(openedAction)} handleClose={() => setOpenedAction(null)} />
      )}
    </Container>
  );
};
