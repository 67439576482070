import React from "react";
import cn from "classnames";
import { Loader, Nullable } from "@epcnetwork/core-ui-kit";
import { ExternalLinkIcon } from "@radix-ui/react-icons";

import { Badge, BadgeProps } from "components";
import { AllJobsStatues } from "models";
import { Check, Circle, Pause, Failed, ExclamationTriangle, MinusCircle } from "assets";

import styles from "./job-item-status.module.scss";

interface Props {
  status: AllJobsStatues;
  successCount?: string | number;
  valuesCount?: number;
  message?: Nullable<string>;
  onErrorBadgeClick?: (error: Nullable<string>) => void;
}

export const JobItemStatus: React.FC<Props> = ({ status, successCount, valuesCount, message, onErrorBadgeClick }) => {
  const statusDetails: Record<AllJobsStatues, { text: string; icon: React.ReactNode; variant: BadgeProps["variant"] }> =
    {
      created: { text: "Created", icon: <Circle />, variant: "default" },
      paused: { text: "Paused", icon: <Pause />, variant: "default" },
      waiting: { text: "Waiting", icon: <MinusCircle />, variant: "default" },
      inProgress: {
        text: "In progress",
        icon: <Loader type="clip-loader" loaderClassName={styles.loader} />,
        variant: "default",
      },
      exporting: { text: "Exporting", icon: <Circle />, variant: "default" },
      processing: {
        text: "Processing",
        icon: <Loader type="clip-loader" loaderClassName={styles.loader} />,
        variant: "default",
      },
      forwarding: { text: "Forwarding", icon: <Circle />, variant: "default" },
      warning: { text: "Warning", icon: <ExclamationTriangle />, variant: "warning" },
      finished: { text: "Finished", icon: <Check />, variant: "finished" },
      success: { text: "Success", icon: <Check />, variant: "success" },
      done: { text: "Done", icon: <Check />, variant: "success" },
      aborting: { text: "Aborting", icon: <Circle />, variant: "default" },
      aborted: { text: "Aborted", icon: <Circle />, variant: "default" },
      empty: { text: "Empty", icon: <Circle />, variant: "default" },
      failed: { text: "Failed", icon: <Failed />, variant: "error" },
      error: { text: "Error", icon: <Failed />, variant: "error" },
    };

  const showNumericValues = successCount !== undefined;
  const showMessage = !!message;

  const handleBadgeClick = () => {
    if (message) {
      onErrorBadgeClick?.(message);
    }
  };

  const badge = (
    <Badge className={cn(styles.badge)} variant={statusDetails[status]?.variant}>
      {statusDetails[status]?.icon}{" "}
      <span>
        {statusDetails[status]?.text}{" "}
        {showNumericValues && (
          <span>
            ({+successCount}/{valuesCount})
          </span>
        )}
      </span>
      {status === "failed" && showMessage && <ExternalLinkIcon width={14} height={14} />}
    </Badge>
  );

  if (showMessage)
    return (
      <button type="button" className={styles.button} onClick={handleBadgeClick}>
        {badge}
      </button>
    );

  return badge;
};
