import React from "react";
import { useFetch } from "@hyper-fetch/react";
import { Button, Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";

import { Container, TableError, TableNoContent } from "components";
import { Navbar } from "components/layout/navbar/navbar";
import { useItemManagement, usePermissions, useSidebar, useTablePagination, useTour } from "hooks";
import { GreenArrowAccountModel } from "models";
import { getGreenArrowAccounts } from "api";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { addItem, getInitialStorageFilters, removeItem, updateItem } from "utils";
import { filtersOptions, TABLE_NAME, greenArrowAccountsColumns } from "./green-arrow-accounts.constants";
import { DeleteResponse } from "api/api.types";
import { GreenArrowAccountItem } from "pages/integrations/green-arrow/table-item/green-arrow-account-item";
import { steps } from "./green-arrow-accounts.tour";
import { Plus } from "assets";
import { CreateGreenArrowAccount } from "./create/create-green-arrow-account";
import { UpdateGreenArrowAccount } from "./update/update-green-arrow";

import styles from "./green-arrow-accounts.module.scss";

export const GreenArrowAccountsPage: React.FC = () => {
  useTour({
    name: "green-arrow-accounts",
    steps,
  });

  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();
  const { create: createAccount } = useItemManagement<GreenArrowAccountModel>();
  const { update } = useItemManagement<GreenArrowAccountModel>();
  const { query, searchValue, setSearch } = useFilters<BaseSearchQuery & BasePaginationQuery>(
    getInitialStorageFilters<BaseSearchQuery & BasePaginationQuery>("green-arrow", filtersOptions),
  );

  const [canCreateAccount] = usePermissions("create", "green-arrow-accounts");

  const { data, loading, refetch, error, setData } = useFetch(
    getGreenArrowAccounts.setQueryParams({ ...query, search: query.search }),
  );

  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const handleEdit = (account: GreenArrowAccountModel) => {
    update.setItem(account);
  };

  const handleCreateAccountSuccess = (account: GreenArrowAccountModel) => {
    if (data) addItem(account, data, setData);
  };

  const handleUpdateAccountSuccess = (account: GreenArrowAccountModel) => {
    if (data) updateItem(account, data, setData);
  };

  const handleRemoveSuccess = (response: DeleteResponse) => {
    if (data) removeItem(response, data, setData);
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  return (
    <>
      <Navbar
        isMenuCollapsed={isMenuCollapsed}
        toggleSidebar={toggleSidebar}
        name="GreenArrow accounts"
        description="List of all GreenArrow accounts."
        navbarClassName="green-arrow-navbar"
      />
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />
            {canCreateAccount && (
              <Button onClick={createAccount.open} className="add-greenarrow-account">
                <Plus /> Add GreenArrow account
              </Button>
            )}
          </div>

          <Table
            entityName={TABLE_NAME}
            columns={greenArrowAccountsColumns}
            list={data?.data}
            error={error?.message}
            refresh={() => refetch()}
            loading={loading}
            resetColumnsOnMount={false}
            isTabTable
            customNoContent={<TableNoContent withBackground />}
            customError={<TableError description={error?.message} withBackground />}
            pagination={{
              ...pagination,
              elementsPerPage: currentElementsPerPage,
              onElementsPerPageChange: handlePerPageChange,
            }}
            row={(account, index) => (
              <GreenArrowAccountItem
                index={index}
                account={account}
                handleEdit={handleEdit}
                onRemoveSuccess={handleRemoveSuccess}
                onActivateSuccess={handleUpdateAccountSuccess}
              />
            )}
          />
          <CreateGreenArrowAccount
            isOpen={createAccount.isOpen}
            close={createAccount.close}
            refresh={refetch}
            onCreateSuccess={handleCreateAccountSuccess}
          />
          {update.item && (
            <UpdateGreenArrowAccount
              account={update.item}
              isOpen={update.isOpen}
              close={update.close}
              onUpdateSuccess={handleUpdateAccountSuccess}
            />
          )}
        </div>
      </Container>
    </>
  );
};
