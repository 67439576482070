import { FC, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form, FormikProps } from "formik";
import {
  Button,
  MessageField,
  FormButtons,
  FormField,
  notification,
  Modal,
  Label,
  Switch,
} from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { UpdateBusinessUnitData, updateRemarketyAccount, UpdateRemarketyAccountData } from "api";
import { RemarketyAccountModel } from "models";
import { validationSchema, initialValues } from "./update-remarkety.constants";

import styles from "./update-remarkety.module.scss";

export type UpdateRemarketyAccountProps = {
  isOpen: boolean;
  close: VoidFunction;
  account: RemarketyAccountModel | null;
  onUpdateSuccess: (value: RemarketyAccountModel) => void;
};

export const UpdateRemarketyAccount: FC<UpdateRemarketyAccountProps> = ({
  isOpen,
  account,
  close,
  onUpdateSuccess,
}) => {
  const [clientSecretEnabled, setClientSecretEnabled] = useState<boolean>(false);

  const { submit, submitting, error, onSubmitSuccess, onSubmitError } = useSubmit(updateRemarketyAccount);
  onSubmitSuccess(({ response }) => {
    onUpdateSuccess(response);
    close();
    notification.success("Remarkety account updated!", "Remarkety account has been successfully updated.");
  });
  onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the Remarkety account.");
  });

  useDidUpdate(() => {
    setClientSecretEnabled(false);
  }, [isOpen]);

  const handleSwitchToggle =
    (formikProps: FormikProps<Partial<UpdateBusinessUnitData>>) => (value: string, checked: boolean) => {
      setClientSecretEnabled(checked);
      if (!checked) {
        formikProps.setFieldValue("apiKey", "");
        formikProps.setFieldTouched("apiKey", false, false);
      }
    };

  const handleSubmit = async (values: UpdateRemarketyAccountData) => {
    if (account) {
      const data = { ...values };
      if (!clientSecretEnabled) delete data.apiKey;

      await submit({
        data,
        params: { accountId: account.id },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik
        initialValues={initialValues(account)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(clientSecretEnabled)}
        enableReinitialize
      >
        {(props) => (
          <Form>
            <div className={styles.title}>Update {account?.name} account</div>
            <p className={styles.description}>
              If the switch is checked, a new API key will be required. When the switch is not checked, the API key that
              was added earlier will be used.
            </p>
            <div className={styles.row}>
              <FormField type="text" name="name" label="Account name" disableFloatingLabel required />
              <FormField type="text" name="storeId" label="Store ID" disableFloatingLabel required />
            </div>
            <div>
              <div className={styles.labelRow}>
                <Label text="API key" isInputLabel />
                <Switch value="test" disableError checked={clientSecretEnabled} onChange={handleSwitchToggle(props)} />
              </div>
              <FormField
                type="text"
                name="clientSecret"
                placeholder={clientSecretEnabled ? "Provide a new API key" : "Use the same API key"}
                disableFloatingLabel
                disabled={!clientSecretEnabled}
              />
            </div>

            <MessageField message={error?.message || ""} />

            <FormButtons className={styles.buttons}>
              <Button appearance="secondary" onClick={close}>
                Cancel
              </Button>
              <Button type="submit" loading={submitting}>
                Submit
              </Button>
            </FormButtons>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
