import { client } from "api/client.api";
import { AutocleaningJobModel, List } from "models";
import { ServerErrorType } from "api/api.types";
import { AutocleaningJobsQuery } from "./autocleaning.types";

export const getAutocleaningJobs = client.createRequest<
  List<AutocleaningJobModel>,
  null,
  ServerErrorType,
  AutocleaningJobsQuery
>()({
  method: "GET",
  endpoint: "/suppressions/auto-jobs",
  options: { timeout: 0 },
});
