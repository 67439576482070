import React, { FC } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { Button, MessageField, FormField, notification, Modal } from "@epcnetwork/core-ui-kit";

import { BlueshiftAccountData, createBlueshiftAccount, getActiveEsps } from "api";
import { validationSchema, initialValues } from "./create-blueshift-account.constants";
import { BlueshiftAccountModel } from "models";

import styles from "./create-blueshift-account.module.scss";

export type CreateBlueshiftAccountProps = {
  isOpen: boolean;
  close: VoidFunction;
  refresh: VoidFunction;
  onCreateSuccess: (value: BlueshiftAccountModel) => void;
};

export const CreateBlueshiftAccount: FC<CreateBlueshiftAccountProps> = ({
  isOpen,
  close,
  refresh,
  onCreateSuccess,
}) => {
  const { submit, error, refetch, submitting, onSubmitSuccess, onSubmitError } = useSubmit(createBlueshiftAccount);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    refetch(getActiveEsps.cacheKey);
    close();
    notification.success("Blueshift account created!", "Blueshift account has been successfully created.");
  });
  onSubmitError(() => {
    notification.error("Create error!", "Error occurred while creating a new Blueshift account.");
  });

  const handleClose = () => {
    close();
  };

  const handleSubmit = async (values: BlueshiftAccountData) => {
    await submit({ data: values });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isValid }) => {
          return (
            <Form>
              <div className={styles.title}>Add Blueshift account</div>

              <FormField type="text" name="name" label="Account name" disableFloatingLabel required />
              <FormField type="text" name="apiKey" label="API key" disableFloatingLabel required />

              <MessageField message={error?.message || ""} />

              <div className={styles.buttons}>
                <Button appearance="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!isValid} loading={submitting}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
