import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Unsubscribe jobs page</h2>
    <p>
      A centralized dashboard displaying all jobs, with a focus on unsubscribe-related jobs. Easily track and manage
      unsubscribe operations, including job statuses and execution progress.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    permission: ["read", "unsub-jobs"],
  },
];
