import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types";
import { DEFAULT_OFFSET } from "constants/query.constants";
import { AutocleaningJobsQuery } from "api";

export const autocleaningListColumns: ColumnsType = [
  { label: "Created at", queryKey: "createdAt" },
  { label: "Status", required: true, queryKey: "status" },
  { label: "" },
];

export const TABLE_NAME = "autocleaning-table";

export const initialFilters: UseFiltersProps<AutocleaningJobsQuery> = {
  initialState: {
    limit: "25",
    offset: DEFAULT_OFFSET,
  },
};
