import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types";
import { DEFAULT_OFFSET } from "constants/query.constants";
import { GetJobsQuery } from "api/jobs";

export const md5JobsColumns: ColumnsType = [
  { label: "User" },
  { label: "Created at", queryKey: "createdAt" },
  { label: "Offers" },
  { label: "Status", required: true, queryKey: "status" },
  { label: "" },
];

export const TABLE_NAME = "export-jobs-table";

export const initialFilters: UseFiltersProps<GetJobsQuery> = {
  initialState: {
    search: "",
    limit: "25",
    offset: DEFAULT_OFFSET,
  },
};
