import { object, string, Schema, number } from "yup";
import { SelectOption } from "@epcnetwork/core-ui-kit";

import { GreenArrowList, UpdateGreenArrowAccountData } from "api";
import { GreenArrowAccountModel } from "models";

export const validationSchema = (clientSecretEnabled: boolean): Schema<UpdateGreenArrowAccountData> =>
  object().shape({
    name: string().required("Name is required"),
    suppressionListId: number().min(1, "List is required").required("List is required"),
    apiUrl: clientSecretEnabled ? string().required("Api URL is required") : string(),
    apiKey: clientSecretEnabled ? string().required("Api Key is required") : string(),
  });

export const initialValues = (account: GreenArrowAccountModel | null): UpdateGreenArrowAccountData => ({
  name: account?.name || "",
  apiUrl: account?.apiUrl || "",
  apiKey: "",
  suppressionListId: account?.suppressionListId || 0,
});

export const convertListsToOptions = (lists: GreenArrowList[]): SelectOption[] =>
  lists.map((item) => ({ label: `${item.name} (ID: ${item.id})`, value: item.id }));
