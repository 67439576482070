import React, { useState } from "react";
import { FormField, notification, SelectOption } from "@epcnetwork/core-ui-kit";
import { DownloadIcon } from "@radix-ui/react-icons";
import { useSubmit } from "@hyper-fetch/react";
import { useFormikContext } from "formik";

import { getGreenArrowSuppressionListsWithCredentials, GreenArrowAccountData } from "api";

import styles from "../create-green-arrow-account.module.scss";

export const SuppressionLists: React.FC = () => {
  const { values } = useFormikContext<GreenArrowAccountData>();

  const [options, setOptions] = useState<SelectOption[]>([]);

  const suppressionLists = useSubmit(getGreenArrowSuppressionListsWithCredentials);
  suppressionLists.onSubmitSuccess(({ response }) => {
    const mappedOptions = response.map((item) => ({ label: `${item.name} (ID: ${item.id})`, value: item.id }));
    setOptions(mappedOptions);
  });
  suppressionLists.onSubmitError(() => {
    notification.error("Could not fetch a suppression lists", "Check if provided credentials are correct.");
  });

  const handleListsFetch = async () => {
    if (values.apiKey && values.apiUrl) {
      await suppressionLists.submit({ data: { apiKey: values.apiKey, apiUrl: values.apiUrl } });
    }
  };

  return (
    <div className={styles.inputRow}>
      <FormField
        name="suppressionListId"
        label="Suppression list"
        disableFloatingLabel
        type="select"
        asyncOptions={{ loading: suppressionLists.submitting }}
        options={options}
      />
      <button
        type="button"
        className={styles.downloadButton}
        disabled={!values.apiKey || !values.apiUrl}
        onClick={handleListsFetch}
      >
        <DownloadIcon />
      </button>
    </div>
  );
};
