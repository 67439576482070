import React, { FC } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { Button, MessageField, FormField, notification, Modal, Label } from "@epcnetwork/core-ui-kit";

import { createCompany, getActiveEsps } from "api";
import { CreateOptizmoCompanyData, CreateOptizmoCompanyProps } from "./create-optizmo-company.types";
import { validationSchema, initialValues } from "./create-optizmo-company.constants";

import styles from "../create-optizmo.module.scss";

export const CreateOptizmoCompany: FC<CreateOptizmoCompanyProps> = ({ isOpen, close, refresh, onCreateSuccess }) => {
  const { submit, submitting, error, refetch, onSubmitSuccess, onSubmitError } = useSubmit(createCompany);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    close();
    refetch(getActiveEsps.cacheKey);
    notification.success("Optizmo company created!", "Optizmo company has been successfully created.");
  });
  onSubmitError(() => {
    notification.error("Create error!", "Error occurred while creating a new Optizmo company.");
  });

  const handleClose = () => {
    close();
  };

  const handleSubmit = async (values: CreateOptizmoCompanyData) => {
    const { optizmoBased, ...rest } = values;
    if (!values?.optizmoToken) delete rest.optizmoToken;

    await submit({ data: rest });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ values, setFieldTouched, isValid, setFieldValue }) => {
          const resetOptizmoToken = async () => {
            await setFieldValue("optizmoToken", "");
            await setFieldTouched("optizmoToken", true);
            await setFieldTouched("optizmoBased", true);
          };

          return (
            <Form>
              <div className={styles.title}>Add Optizmo company</div>
              <MessageField message={error?.message || ""} />

              <FormField type="text" name="name" label="Company name" disableFloatingLabel required />

              <div>
                <div className={styles.labelRow}>
                  <div>
                    <Label text="Optizmo based" isInputLabel />
                  </div>
                  <FormField type="switch" name="optizmoBased" disableError onChange={resetOptizmoToken} />
                </div>
              </div>

              <FormField
                type="text"
                name="optizmoToken"
                required
                label="Optizmo token"
                disableFloatingLabel
                disabled={!values.optizmoBased}
              />

              <div className={styles.buttons}>
                <Button appearance="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!isValid} loading={submitting}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
