import { FC, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form, FormikProps } from "formik";
import {
  Button,
  MessageField,
  FormButtons,
  FormField,
  notification,
  Modal,
  Label,
  Switch,
} from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { updateBlueshiftAccount, UpdateBlueshiftAccountData } from "api";
import { BlueshiftAccountModel } from "models";
import { validationSchema, initialValues } from "./update-blueshift.constants";

import styles from "./update-blueshift.module.scss";

export type UpdateBlueshiftAccountProps = {
  isOpen: boolean;
  close: VoidFunction;
  account: BlueshiftAccountModel | null;
  onUpdateSuccess: (value: BlueshiftAccountModel) => void;
};

export const UpdateBlueshiftAccount: FC<UpdateBlueshiftAccountProps> = ({
  isOpen,
  account,
  close,
  onUpdateSuccess,
}) => {
  const [apiKeyEnabled, setApiKeyEnabled] = useState<boolean>(false);

  const { submit, submitting, error, onSubmitSuccess, onSubmitError } = useSubmit(updateBlueshiftAccount);
  onSubmitSuccess(({ response }) => {
    onUpdateSuccess(response);
    close();
    notification.success("Blueshift account updated!", "Blueshift account has been successfully updated.");
  });
  onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the Blueshift account.");
  });

  useDidUpdate(() => {
    setApiKeyEnabled(false);
  }, [isOpen]);

  const handleSwitchToggle =
    (formikProps: FormikProps<Partial<UpdateBlueshiftAccountData>>) => (value: string, checked: boolean) => {
      setApiKeyEnabled(checked);
      if (!checked) {
        formikProps.setFieldValue("clientSecret", "");
        formikProps.setFieldTouched("clientSecret", false, false);
      }
    };

  const handleSubmit = async (values: UpdateBlueshiftAccountData) => {
    if (account) {
      const data = { ...values };
      if (!apiKeyEnabled) delete data.apiKey;

      await submit({
        data,
        params: { accountId: account.id },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik
        initialValues={initialValues(account)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(apiKeyEnabled)}
        enableReinitialize
      >
        {(props) => (
          <Form>
            <div className={styles.title}>Update {account?.name} account</div>
            <p className={styles.description}>
              If the switch is checked, a new client secret will be required. When the switch is not checked, the client
              secret that was added earlier will be used.
            </p>

            <FormField type="text" name="name" label="Account name" disableFloatingLabel required />

            <div className={styles.labelRow}>
              <Label text="Client secret" isInputLabel />
              <Switch value="test" disableError checked={apiKeyEnabled} onChange={handleSwitchToggle(props)} />
            </div>
            <FormField
              type="text"
              name="apiKey"
              placeholder={apiKeyEnabled ? "Provide a new API key" : "Use the same API key"}
              disableFloatingLabel
              disabled={!apiKeyEnabled}
            />

            <MessageField message={error?.message || ""} />

            <FormButtons className={styles.buttons}>
              <Button appearance="secondary" onClick={close}>
                Cancel
              </Button>
              <Button type="submit" loading={submitting}>
                Submit
              </Button>
            </FormButtons>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
