import React from "react";
import { useFetch } from "@hyper-fetch/react";
import { Button, Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";

import { Container, TableError, TableNoContent } from "components";
import { Navbar } from "components/layout/navbar/navbar";
import { useItemManagement, usePermissions, useSidebar, useTablePagination, useTour } from "hooks";
import { InsiderAccountModel } from "models";
import { getInsiderAccounts } from "api";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { addItem, getInitialStorageFilters, removeItem, updateItem } from "utils";
import { filtersOptions, TABLE_NAME, insiderAccountsColumns } from "./insider-accounts.constants";
import { DeleteResponse } from "api/api.types";
import { steps } from "./insider-accounts.tour";
import { InsiderAccountItem } from "./table-item/insider-account-item";
import { CreateInsiderAccount } from "./create/create-insider-account";
import { UpdateInsiderAccount } from "./update/update-insider";
import { Plus } from "assets";

import styles from "./insider-accounts.module.scss";

export const InsiderAccountsPage: React.FC = () => {
  useTour({
    name: "insider-accounts",
    steps,
  });

  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();
  const { create: createAccount } = useItemManagement<InsiderAccountModel>();
  const { update } = useItemManagement<InsiderAccountModel>();
  const { query, searchValue, setSearch } = useFilters<BaseSearchQuery & BasePaginationQuery>(
    getInitialStorageFilters<BaseSearchQuery & BasePaginationQuery>("insider", filtersOptions),
  );

  const [canCreateAccount] = usePermissions("create", "insider-accounts");

  const { data, loading, refetch, error, setData } = useFetch(
    getInsiderAccounts.setQueryParams({ ...query, search: query.search }),
  );

  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const handleEdit = (account: InsiderAccountModel) => {
    update.setItem(account);
  };

  const handleCreateAccountSuccess = (account: InsiderAccountModel) => {
    if (data) addItem(account, data, setData);
  };

  const handleUpdateAccountSuccess = (account: InsiderAccountModel) => {
    if (data) updateItem(account, data, setData);
  };

  const handleRemoveSuccess = (response: DeleteResponse) => {
    if (data) removeItem(response, data, setData);
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  return (
    <>
      <Navbar
        isMenuCollapsed={isMenuCollapsed}
        toggleSidebar={toggleSidebar}
        name="Insider accounts"
        description="List of all Insider accounts."
        navbarClassName="insider-navbar"
      />
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />
            {canCreateAccount && (
              <Button onClick={createAccount.open} className="add-insider-account">
                <Plus /> Add Insider account
              </Button>
            )}
          </div>

          <Table
            entityName={TABLE_NAME}
            columns={insiderAccountsColumns}
            list={data?.data}
            error={error?.message}
            refresh={() => refetch()}
            loading={loading}
            resetColumnsOnMount={false}
            isTabTable
            customNoContent={<TableNoContent withBackground />}
            customError={<TableError description={error?.message} withBackground />}
            pagination={{
              ...pagination,
              elementsPerPage: currentElementsPerPage,
              onElementsPerPageChange: handlePerPageChange,
            }}
            row={(account, index) => (
              <InsiderAccountItem
                index={index}
                account={account}
                handleEdit={handleEdit}
                onRemoveSuccess={handleRemoveSuccess}
                onActivateSuccess={handleUpdateAccountSuccess}
              />
            )}
          />
          <CreateInsiderAccount
            isOpen={createAccount.isOpen}
            close={createAccount.close}
            refresh={refetch}
            onCreateSuccess={handleCreateAccountSuccess}
          />
          <UpdateInsiderAccount
            account={update.item}
            isOpen={update.isOpen}
            close={update.close}
            onUpdateSuccess={handleUpdateAccountSuccess}
          />
        </div>
      </Container>
    </>
  );
};
