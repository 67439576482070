import React, { useRef } from "react";
import { Form, Formik, FormikProps } from "formik";
import { useSubmit } from "@hyper-fetch/react";
import { notification } from "@epcnetwork/core-ui-kit";
import { useNavigate } from "react-router-dom";

import { Container } from "components";
import { OptizmoSelector } from "./optizmo-selector/optizmo-selector";
import { EspSelector } from "./esp-selector/esp-selector";
import { OptizmoExportData } from "./list-cleaning.types";
import { initialValues, validationSchema } from "./list-cleaning.constants";
import { createOfferMd5s, MatchMd5sData } from "api";
import { LIST_CLEANING_JOBS_PAGE } from "constants/routes.constants";
import { OptizmoExportContextProvider } from "./list-cleaning.context";
import { useTour } from "hooks";
import { steps } from "./list-cleaning.tour";

import styles from "./list-cleaning.module.scss";

export const ListCleaningPage: React.FC = () => {
  useTour({
    name: "md5-export",
    steps,
  });

  const formikRef = useRef<FormikProps<OptizmoExportData> | null>(null);
  const submitTypeValue = useRef<"lists" | "dataExtensions" | null>(null);
  const navigate = useNavigate();

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(createOfferMd5s);
  onSubmitSuccess(() => {
    notification.success("Success!", "Export created.");
    navigate(LIST_CLEANING_JOBS_PAGE.path);
  });
  onSubmitError(({ response }) => {
    if (submitTypeValue.current) {
      formikRef.current?.setFieldValue("espData.type", submitTypeValue.current);
    }

    const message = response?.statusCode === 400 ? response?.message : "Could not create an export.";

    notification.error("Error!", message);
  });

  const handleSubmit = async (values: OptizmoExportData) => {
    const { offerId, espData } = values;
    if ("type" in espData) {
      submitTypeValue.current = espData.type || null;
    }

    if ("type" in espData) delete espData.type;

    if (espData.esp === "iterable") {
      const { accountId, ...rest } = espData;
      const data: MatchMd5sData = { offerId, espData: rest };

      await submit({ data });
    }

    if (espData.esp === "salesforce") {
      const data: MatchMd5sData = { offerId, espData };
      await submit({ data });
    }
  };

  return (
    <Container>
      <OptizmoExportContextProvider>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className={styles.wrapper}>
                  <OptizmoSelector />
                  <EspSelector />
                </div>
              </Form>
            );
          }}
        </Formik>
      </OptizmoExportContextProvider>
    </Container>
  );
};
