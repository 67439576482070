import React, { useState } from "react";
import { FormField, Label, SelectOption } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";

import { getBlueshiftActiveAccounts } from "api";

import styles from "../unsub-list-select.module.scss";

export const BlueshiftUnsub: React.FC = () => {
  const [accountOptions, setAccountOptions] = useState<SelectOption<number>[]>([]);

  const accounts = useFetch(getBlueshiftActiveAccounts);
  accounts.onSuccess(({ response }) => {
    const options = response.map((account) => ({ label: account.name, value: account.id }));
    setAccountOptions(options);
  });

  return (
    <div className={styles.container}>
      <div>
        <Label text="Select a Blueshift account" isInputLabel />
        <p className={styles.description}>Select the account you want to unsubscribe from</p>
        <FormField
          type="select"
          label="Account"
          name="data.properties.blueshiftAccountId"
          options={accountOptions}
          isSearchable
          asyncOptions={{ loading: accounts.loading }}
        />
      </div>
    </div>
  );
};
