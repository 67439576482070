import { number, object, Schema, string } from "yup";

import {
  IterableData,
  SalesforceData,
  OptizmoExportData,
  SalesforceDataExtensionData,
  SalesforceListData,
  SalesforceDataExtensionsData,
  SalesforceListsData,
} from "./list-cleaning.types";

/* ITERABLE initial values */

export const initialIterableListData = { id: 0, name: "" };

export const initialIterableData: IterableData = {
  esp: "iterable",
  accountId: 0,
  projectId: 0,
  fromList: initialIterableListData,
  toList: initialIterableListData,
};

/* SALESFORCE initial values */

export const initialSalesforceDataExtensionData: SalesforceDataExtensionData = {
  customerKey: "",
  emailProperty: "",
  name: "",
};

export const initialSalesforceDataExtensionsData: SalesforceDataExtensionsData = {
  type: "dataExtensions",
  fromList: initialSalesforceDataExtensionData,
  toList: initialSalesforceDataExtensionData,
};

export const initialSalesforceListData: SalesforceListData = {
  name: "",
  id: 0,
};

export const initialSalesforceListsData: SalesforceListsData = {
  type: "lists",
  fromList: initialSalesforceListData,
  toList: initialSalesforceListData,
};

export const initialSalesforceData: SalesforceData = {
  esp: "salesforce",
  businessUnitId: 0,

  type: "dataExtensions", // Used only for conditional validation

  data: {
    type: "dataExtensions",
    fromList: initialSalesforceDataExtensionData,
    toList: initialSalesforceDataExtensionData,
  },
};

export const initialValues: OptizmoExportData = {
  companyId: 0,
  clientId: 0,
  offerId: 0,
  esp: "iterable",
  espData: initialIterableData,
};

/* ITERABLE validation schema */

export const iterableDataSchema: Schema<IterableData> = object().shape({
  esp: string().oneOf(["iterable"]).required("ESP is required"),
  accountId: number().min(1, "Account is required").required("Account is required"),
  projectId: number().min(1, "Project is required").required("Project is required"),
  fromList: object({
    id: number().min(1, "List is required").required("List is required"),
    name: string().required("List name is required"),
  }).required("From list is required"),
  toList: object({
    id: number().min(1, "List is required").required("List is required"),
    name: string().required("List name is required"),
  }).required("From list is required"),
});

/* SALESFORCE validation schema */

const salesforceDataExtensionSchema: Schema<SalesforceDataExtensionData> = object().shape({
  customerKey: string().required("Customer key is required"),
  name: string().required("Name is required"),
  emailProperty: string().required("Email property is required"),
});

const salesforceDataExtensionsSchema: Schema<SalesforceDataExtensionsData> = object().shape({
  type: string().oneOf(["dataExtensions"]).required("Type is required"),

  fromList: salesforceDataExtensionSchema,
  toList: salesforceDataExtensionSchema,
});

export const salesforceListSchema: Schema<SalesforceListData> = object().shape({
  name: string().required("Name is required"),
  id: number().min(0, "ID is required").required("ID is required"),
});

const salesforceListsSchema: Schema<SalesforceListsData> = object().shape({
  type: string().oneOf(["lists"]).required("Type is required"),

  fromList: salesforceListSchema,
  toList: salesforceListSchema,
});

// eslint-disable-next-line
// @ts-ignore
export const salesforceDataSchema: Schema<SalesforceData> = object().shape({
  esp: string().oneOf(["salesforce"]).required("ESP is required"),
  businessUnitId: number().min(1, "Business unit is required").required("Business unit is required"),

  type: string().oneOf(["dataExtensions", "lists"]),
  data: object()
    .when("type", ([type]) => {
      if (type === "dataExtensions") return salesforceDataExtensionsSchema;
      return salesforceListsSchema;
    })
    .required(),
});

// eslint-disable-next-line
// @ts-ignore
export const validationSchema: Schema<OptizmoExportData> = object().shape({
  companyId: number().min(1, "Company is required").required("Company is required"),
  clientId: number().min(1, "Client is required").required("Client is required"),
  offerId: number().min(1, "Offer is required").required("Offer is required"),
  esp: string().oneOf(["iterable", "salesforce"]).required("ESP is required"),
  espData: object()
    .when("esp", ([esp]) => {
      if (esp === "salesforce") return salesforceDataSchema;
      return iterableDataSchema;
    })
    .required("ESP data is required"),
});
