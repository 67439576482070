import React, { useState } from "react";
import { FormField, Label, SelectOption } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";
import { useFormikContext } from "formik";

import {
  getActiveAccounts,
  getIterableActiveLists,
  getIterableActiveProjects,
  UnsubscribeFormData,
  IterableUnsubscribeData,
} from "api";

import styles from "../unsub-list-select.module.scss";

export const IterableUnsub: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<UnsubscribeFormData>();

  const iterableData = values.data.properties as IterableUnsubscribeData;

  const [accountOptions, setAccountOptions] = useState<SelectOption<number>[]>([]);
  const [projectOptions, setProjectOptions] = useState<SelectOption<number>[]>([]);
  const [listOptions, setListOptions] = useState<SelectOption<number>[]>([]);

  const accounts = useFetch(getActiveAccounts);
  accounts.onSuccess(({ response }) => {
    const options = response.map((account) => ({ label: account.name, value: account.id }));
    setAccountOptions(options);
  });

  const projects = useFetch(
    getIterableActiveProjects.setQueryParams({ accountId: (iterableData.accountId || 0).toString() }),
    {
      disabled: !iterableData.accountId,
      dependencies: [iterableData.accountId],
    },
  );
  projects.onSuccess(async ({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setProjectOptions(options);

    if (options?.[0]) await setFieldValue("data.properties.projectId", options[0].value);
  });

  const lists = useFetch(getIterableActiveLists.setParams({ projectId: (iterableData.projectId || 0).toString() }), {
    disabled: !iterableData.projectId,
    dependencies: [iterableData.projectId],
  });
  lists.onSuccess(async ({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setListOptions(options);

    if (options?.[0]) await setFieldValue("data.properties.listId", options[0].value);
  });

  return (
    <div className={styles.container}>
      <div>
        <Label text="Select an account" isInputLabel />
        <p className={styles.description}>Select the account you want to unsubscribe from</p>
        <FormField
          type="select"
          label="Account"
          name="data.properties.accountId"
          options={accountOptions}
          isSearchable
          asyncOptions={{ loading: accounts.loading }}
        />
      </div>

      <div className={styles.wrapper}>
        <Label text="Select a project" isInputLabel />
        <p className={styles.description}>Select the project you want to unsubscribe from</p>
        <FormField
          type="select"
          label="Project"
          name="data.properties.projectId"
          options={projectOptions}
          disabled={!iterableData.accountId}
          isSearchable
          asyncOptions={{ loading: projects.loading }}
        />
      </div>

      <div className={styles.wrapper}>
        <Label text="Select a list" isInputLabel />
        <p className={styles.description}>Select the list you want to unsubscribe from</p>
        <FormField
          type="select"
          label="List"
          name="data.properties.listId"
          options={listOptions}
          isSearchable
          disabled={!iterableData.projectId}
          asyncOptions={{ loading: lists.loading }}
        />
      </div>
    </div>
  );
};
