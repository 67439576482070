import React, { Dispatch, SetStateAction, useState } from "react";
import { FormField, Loader, SelectOption } from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";
import { useFormikContext } from "formik";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { DataExtensionFieldsResponse, getDataExtensionFields } from "api";
import {
  OptizmoExportData,
  SalesforceData,
  SalesforceDataExtensionData,
} from "pages/md5/list-cleaning/list-cleaning.types";

import styles from "pages/md5/list-cleaning/list-cleaning.module.scss";

type Props = {
  setFromFields: Dispatch<SetStateAction<DataExtensionFieldsResponse[]>>;
};

export const FieldsFrom: React.FC<Props> = ({ setFromFields }) => {
  const { values } = useFormikContext<OptizmoExportData>();

  const espData = values.espData as SalesforceData;

  const [fieldsLoading, setFieldsLoading] = useState<boolean>(false);
  const [fieldOptions, setFieldOptions] = useState<SelectOption<string>[]>([]);

  const dataExtensionFields = useSubmit(getDataExtensionFields);
  dataExtensionFields.onSubmitRequestStart(() => {
    // we need to handle it manually since we have 2 request on same page and there's problem with caching
    setFieldsLoading(true);
  });
  dataExtensionFields.onSubmitFinished(() => {
    setFieldsLoading(false);
  });
  dataExtensionFields.onSubmitSuccess(({ response }) => {
    setFromFields(response);
    if (response) {
      const options: SelectOption<string>[] = response.map((field) => ({
        label: field.Name,
        value: field.Name,
      }));
      setFieldOptions(options);
    }
  });

  useDidUpdate(() => {
    // fetch data extension fields
    if (espData.businessUnitId) {
      const data = espData.data.fromList as SalesforceDataExtensionData;

      if (data.customerKey) {
        dataExtensionFields.submit({
          data: { customerKey: data.customerKey },
          params: { businessUnitId: espData.businessUnitId },
        });
      }
    }
  }, [espData.businessUnitId, (espData.data.fromList as SalesforceDataExtensionData)?.customerKey]);

  return (
    <div>
      {!!espData.businessUnitId && !!(espData.data.fromList as SalesforceDataExtensionData).customerKey && (
        <div>
          <p className={styles.projectDescription}>Select an email field for this data extension:</p>

          {fieldsLoading && <Loader type="clip-loader" />}
          {!fieldsLoading && fieldOptions.length === 0 && <p className={styles.noContentText}>No fields to display</p>}
          {!fieldsLoading && fieldOptions.length > 0 && (
            <div className={styles.column}>
              <FormField
                type="radio"
                name="espData.data.fromList.emailProperty"
                options={fieldOptions}
                disabled={!espData?.businessUnitId}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
