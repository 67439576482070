import React, { useRef } from "react";
import { useFetch } from "@hyper-fetch/react";
import { Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";

import { Container, TableError, TableNoContent } from "components";
import { useTablePagination, useTour } from "hooks";
import { getUnsubscribeJobs } from "api";
import { JobItem } from "./table-item/job-item";
import { getInitialStorageFilters } from "utils";
import { initialFilters, jobListColumns, TABLE_NAME } from "./job-list.constants";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { steps } from "./jobs-list.tour";

import styles from "./jobs-list.module.scss";

export const UnsubscribeJobsListPage: React.FC = () => {
  useTour({
    name: "unsubscribe-jobs-list",
    steps,
  });

  const pageSizeRef = useRef<number>(10);

  const { query, searchValue, setSearch } = useFilters<BasePaginationQuery & BaseSearchQuery>(
    getInitialStorageFilters<BasePaginationQuery & BaseSearchQuery>(TABLE_NAME, initialFilters),
  );

  const { data: jobs, loading, refetch, error } = useFetch(getUnsubscribeJobs.setQueryParams(query));

  const pagination = usePagination({ listPayload: jobs });
  const onElementsPerPageChange = (value: number) => {
    pagination.onElementsPerPageChange(value);
    pageSizeRef.current = value;
  };
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  return (
    <Container>
      <div className={styles.header}>
        <Search searchValue={searchValue} setSearch={setSearch} />
      </div>

      <Table
        entityName={TABLE_NAME}
        columns={jobListColumns}
        list={jobs?.data}
        error={error?.message}
        refresh={() => refetch()}
        loading={loading}
        resetColumnsOnMount={false}
        isTabTable
        customNoContent={<TableNoContent withBackground />}
        customError={<TableError description={error?.message} withBackground />}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
        row={(job) => <JobItem job={job} />}
      />
    </Container>
  );
};
