import React, { useMemo, useState } from "react";
import { Button, notification, Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useFetch, useSubmit } from "@hyper-fetch/react";
import { useParams } from "react-router-dom";
import cn from "classnames";

import {
  BreadcrumbsOption,
  Container,
  DeleteMultipleButton,
  TableError,
  TableNoContent,
  SelectAllButton,
} from "components";
import { Navbar } from "components/layout/navbar/navbar";
import { useItemManagement, usePermissions, useSidebar, useTablePagination, useTour } from "hooks";
import { OfferModel } from "models";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { addItem, getInitialStorageFilters, getLinkPath, removeItem, updateItem } from "utils";
import { filtersOptions, TABLE_NAME, optizmoOffersColumns } from "./optizmo-offers.constants";
import { deleteClients, getClient, getCompany, getOffers } from "api";
import { DeleteResponse } from "api/api.types";
import { OPTIZMO_CLIENTS_LIST_PAGE, OPTIZMO_COMPANIES_LIST_PAGE } from "constants/routes.constants";
import { CreateOptizmoOffer, UpdateOptizmoOffer } from "pages/integrations/optizmo";
import { OptizmoOfferItem } from "./table-item/optizmo-offer-item";
import { steps } from "./optizmo-offers.tour";
import { Plus } from "assets";

import styles from "./optizmo-offers.module.scss";

export const OptizmoOffersPage: React.FC = () => {
  useTour({
    name: "optizmo-offers",
    steps,
  });

  const { companyId, clientId } = useParams<{ companyId: string; clientId: string }>();
  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();
  const { create } = useItemManagement<OfferModel>();
  const { update } = useItemManagement<OfferModel>();
  const { query, searchValue, setSearch } = useFilters<BaseSearchQuery & BasePaginationQuery>(
    getInitialStorageFilters<BaseSearchQuery & BasePaginationQuery>("optizmo", filtersOptions),
  );

  const [canCreateAccount] = usePermissions("create", "accounts");
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const clientDetails = useFetch(getClient.setParams({ clientId: clientId || "" }), {
    disabled: !clientId,
  });

  const companyDetails = useFetch(getCompany.setParams({ companyId: companyId || "" }), {
    disabled: !companyId,
  });

  const { data, loading, refetch, error, setData } = useFetch(
    getOffers.setQueryParams({ ...query, search: query.search, clientId: clientId || "" }),
  );

  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(deleteClients);
  onSubmitSuccess(() => {
    notification.success("Optizmo clients deleted", "Optizmo clients successfully deleted");
    refetch();
    setSelectedRows([]);
  });
  onSubmitError(() => {
    notification.error("Delete error", "Cannot delete these Optizmo clients.");
  });

  const handleEdit = (offer: OfferModel) => {
    update.setItem(offer);
  };

  const handleCreateAccountSuccess = (offer: OfferModel) => {
    if (data) addItem(offer, data, setData);
  };

  const handleUpdateAccountSuccess = (offer: OfferModel) => {
    if (data) updateItem(offer, data, setData);
  };

  const handleRemoveSuccess = (response: DeleteResponse) => {
    if (data) removeItem(response, data, setData);
  };

  const handleItemSelect = (id: number, checked: boolean) => {
    const rows = checked ? [id, ...selectedRows] : selectedRows.filter((row) => row !== id);
    setSelectedRows(rows);
  };

  const handleMultipleDelete = () => {
    notification.confirm(
      `Do you want to delete ${selectedRows.length} Optizmo offer${selectedRows.length > 1 ? "s" : ""}?`,
      "This action is irreversible.",
      {
        onOk: async () => {
          await submit({ data: { ids: selectedRows } });
        },
      },
    );
  };

  const allSelected = useMemo(() => {
    if (!data?.data) return false;

    return selectedRows.length === data.data.length;
  }, [selectedRows, data]);

  const handleSelectAll = () => {
    if (data?.data) {
      const ids = data.data.map((offer) => offer.id);
      setSelectedRows(selectedRows.length === ids.length ? [] : ids);
    }
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const backPath = getLinkPath(OPTIZMO_CLIENTS_LIST_PAGE.path, { companyId: companyId });

  const breadcrumbs: BreadcrumbsOption[] = [
    { name: "Optizmo and hashes", path: OPTIZMO_COMPANIES_LIST_PAGE.path },
    {
      name: companyDetails.data?.name || "",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      path: getLinkPath(OPTIZMO_CLIENTS_LIST_PAGE.path, { companyId: companyId }),
    },
    clientDetails.data?.name || "",
  ];

  return (
    <>
      <Navbar
        isMenuCollapsed={isMenuCollapsed}
        toggleSidebar={toggleSidebar}
        name="Optizmo offers"
        navbarClassName="optizmo-offers-navbar"
        backPage={backPath}
        breadcrumbs={breadcrumbs}
      >
        <div className={cn(styles.row, "active-switch")}>
          {companyDetails.data?.isActive && clientDetails.data?.isActive && (
            <div className={styles.notActiveText}>
              Optizmo company and client is currently active. All active offers will be included.
            </div>
          )}
          {(!companyDetails.data?.isActive || !clientDetails.data?.isActive) && (
            <div className={styles.notActiveText}>
              Optizmo company or client is not active. None of these offers will be included for suppressions.
            </div>
          )}
        </div>
      </Navbar>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />

            <div className={styles.row}>
              {selectedRows.length > 0 && (
                <DeleteMultipleButton
                  onClick={handleMultipleDelete}
                  text={`Delete selected offers (${selectedRows.length})`}
                  total={selectedRows.length}
                />
              )}

              <SelectAllButton onClick={handleSelectAll} allSelected={allSelected} />

              {canCreateAccount && (
                <Button onClick={create.open} className="add-optizmo-offer" btnSize="small">
                  <Plus /> Add Optizmo offer
                </Button>
              )}
            </div>
          </div>

          <Table
            entityName={TABLE_NAME}
            columns={optizmoOffersColumns}
            list={data?.data}
            error={error?.message}
            refresh={() => refetch()}
            loading={loading}
            resetColumnsOnMount={false}
            isTabTable
            customNoContent={<TableNoContent withBackground />}
            customError={<TableError description={error?.message} withBackground />}
            pagination={{
              ...pagination,
              elementsPerPage: currentElementsPerPage,
              onElementsPerPageChange: handlePerPageChange,
            }}
            row={(offer, index) => (
              <OptizmoOfferItem
                index={index}
                offer={offer}
                handleEdit={handleEdit}
                onRemoveSuccess={handleRemoveSuccess}
                onActivateSuccess={handleUpdateAccountSuccess}
                isSelected={selectedRows.includes(offer.id)}
                onSelect={handleItemSelect}
                active={companyDetails.data?.isActive && clientDetails.data?.isActive}
              />
            )}
          />
          <CreateOptizmoOffer
            isOpen={create.isOpen}
            close={create.close}
            onCreateSuccess={handleCreateAccountSuccess}
            company={companyDetails.data}
          />
          <UpdateOptizmoOffer
            offer={update.item}
            isOpen={update.isOpen}
            close={update.close}
            onUpdateSuccess={handleUpdateAccountSuccess}
            company={companyDetails.data}
          />
        </div>
      </Container>
    </>
  );
};
