import { object, Schema, string } from "yup";

import { requiredFieldText } from "constants/form.constants";
import { UnsubManuallyFormData } from "./unsub-manually.types";
import { espUnsubValidationSchema, initialEspValues, MAX_UNSUBSCRIBE_EMAILS } from "../create-unsub.constants";

export const initialValues: UnsubManuallyFormData = {
  emails: "",
  data: initialEspValues,
};

export const validationSchema: Schema<UnsubManuallyFormData> = object().shape({
  emails: string()
    .required(requiredFieldText)
    .test({
      name: "separated",
      message: "Emails should be separated with a new line.",
      test: (value) => {
        const emails = value.split(",");

        return emails.length <= 1;
      },
    })
    .test({
      name: "emails",
      message: "All values should be emails.",
      test: (value) => {
        const emailRegex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const emails = value.split(/\r?\n/).filter(Boolean);

        return emails.every((email) => emailRegex.test(email));
      },
    })
    .test({
      name: "max-emails",
      message: `Maximum ${MAX_UNSUBSCRIBE_EMAILS} emails.`,
      test: (value) => {
        const emails = value.split(/\r?\n/).filter(Boolean);
        return emails.length <= MAX_UNSUBSCRIBE_EMAILS;
      },
    }),

  data: espUnsubValidationSchema,
});
