import React, { useMemo, useState } from "react";
import { Button, Label, Select, SelectOption, TextEllipsis } from "@epcnetwork/core-ui-kit";
import { useNavigate } from "react-router-dom";
import { useFetch } from "@hyper-fetch/react";
import { List, CheckCircle } from "lucide-react";
import cn from "classnames";

import {
  getActiveDataExtensions,
  getActiveEsps,
  getActiveProjects,
  getBlueshiftActiveAccounts,
  getGreenArrowActiveAccounts,
  getInsiderActiveAccounts,
  getMarketoActiveAccounts,
  getRemarketyActiveAccounts,
} from "api";
import { BUSINESS_UNIT_DETAILS_PAGE, ITERABLE_ACCOUNTS_LIST_PAGE } from "constants/routes.constants";
import { Plus } from "assets";

import styles from "./lists-select.module.scss";

type EspSelector = { list: number[]; set: React.Dispatch<React.SetStateAction<number[]>> };

interface Props {
  iterable: EspSelector;
  salesforce: EspSelector;
  remarkety: EspSelector;
  marketo: EspSelector;
  greenarrow: EspSelector;
  insider: EspSelector;
  blueshift: EspSelector;
}

export const ListsSelect: React.FC<Props> = ({
  iterable,
  salesforce,
  remarkety,
  marketo,
  greenarrow,
  insider,
  blueshift,
}) => {
  const navigate = useNavigate();

  const [projectsOptions, setProjectOptions] = useState<SelectOption<number>[]>([]);
  const [dataExtensionsOptions, setDataExtensionsOptions] = useState<SelectOption<number>[]>([]);
  const [remarketyAccountsOptions, setRemarketyAccountsOptions] = useState<SelectOption<number>[]>([]);
  const [marketoAccountsOptions, setMarketoAccountsOptions] = useState<SelectOption<number>[]>([]);
  const [insiderAccountsOptions, setInsiderAccountsOptions] = useState<SelectOption<number>[]>([]);
  const [greenarrowAccountsOptions, setGreenArrowAccountsOptions] = useState<SelectOption<number>[]>([]);
  const [blueshiftAccountsOptions, setBlueshiftAccountsOptions] = useState<SelectOption<number>[]>([]);

  const { data: activeEsps } = useFetch(getActiveEsps);

  const { data: projects, onSuccess: onProjectsSuccess, loading: projectsLoading } = useFetch(getActiveProjects);
  onProjectsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setProjectOptions(options);
    iterable.set(response.map((item) => item.id));
  });

  const {
    data: dataExtensions,
    onSuccess: onDataExtensionsSuccess,
    loading: dataExtensionsLoading,
  } = useFetch(getActiveDataExtensions);
  onDataExtensionsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setDataExtensionsOptions(options);
    salesforce.set(response.map((item) => item.id));
  });

  const { onSuccess: onRemarketyAccountsSuccess, loading: remarketyAccountsLoading } =
    useFetch(getRemarketyActiveAccounts);
  onRemarketyAccountsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setRemarketyAccountsOptions(options);
    remarkety.set(response.map((item) => item.id));
  });

  const { onSuccess: onMarketoAccountsSuccess, loading: marketoAccountsLoading } = useFetch(getMarketoActiveAccounts);
  onMarketoAccountsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setMarketoAccountsOptions(options);
    marketo.set(response.map((item) => item.id));
  });

  const { onSuccess: onGreenArrowAccountsSuccess, loading: greenarrowAccountsLoading } =
    useFetch(getGreenArrowActiveAccounts);
  onGreenArrowAccountsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setGreenArrowAccountsOptions(options);
    greenarrow.set(response.map((item) => item.id));
  });

  const { onSuccess: onInsiderAccountsSuccess, loading: insiderAccountsLoading } = useFetch(getInsiderActiveAccounts);
  onInsiderAccountsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setInsiderAccountsOptions(options);
    insider.set(response.map((item) => item.id));
  });

  const { onSuccess: onBlueshiftAccountsSuccess, loading: blueshiftAccountsLoading } =
    useFetch(getBlueshiftActiveAccounts);
  onBlueshiftAccountsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setBlueshiftAccountsOptions(options);
    blueshift.set(response.map((item) => item.id));
  });

  const accounts = useMemo(() => {
    if (!projects || projects?.length === 0) return [];

    return projects.reduce<{ id: number; name: string }[]>((acc, value) => {
      if (!acc.some((item) => item.id === value.accountId)) {
        acc.push({ id: value.accountId, name: value.accountName });
      }

      return acc;
    }, []);
  }, [projects]);

  const allFromAccountSelected = (accountId: number) => {
    if (!projects || projects?.length === 0 || accounts.length === 0) return false;

    const accountProjects = projects.filter((project) => project.accountId === accountId);

    return accountProjects.every((project) => iterable.list.includes(project.id));
  };

  const handleSelectFromAccount = (accountId: number) => () => {
    const accountProjectsIds =
      projects?.filter((project) => project.accountId === accountId)?.map((project) => project.id) || [];

    const haveAllSelected = accountProjectsIds.every((projectId) => iterable.list.includes(projectId));

    if (haveAllSelected) {
      // filter all projects from account
      iterable.set((prevState) => prevState.filter((project) => !accountProjectsIds?.includes(project)));
      return;
    }

    // filter already selected projects from an account and then select all from that account
    iterable.set((prevState) => {
      const filteredProjects = prevState.filter((project) => !accountProjectsIds?.includes(project));
      return [...filteredProjects, ...accountProjectsIds];
    });
  };

  const businessUnits = useMemo(() => {
    if (!dataExtensions || dataExtensions?.length === 0) return [];

    return dataExtensions.reduce<{ id: number; name: string }[]>((acc, value) => {
      if (!acc.some((item) => item.id === value.businessUnitId)) {
        acc.push({ id: value.businessUnitId, name: value.businessUnitName });
      }

      return acc;
    }, []);
  }, [dataExtensions]);

  const allFromBusinessUnitSelected = (businessUnitId: number) => {
    if (!dataExtensions || dataExtensions?.length === 0 || accounts.length === 0) return false;

    const unitDataExtensions = dataExtensions.filter(
      (dataExtension) => dataExtension.businessUnitId === businessUnitId,
    );

    return unitDataExtensions.every((dataExtension) => salesforce.list.includes(dataExtension.id));
  };

  const handleSelectFromBusinessUnit = (businessUnitId: number) => () => {
    const unitDataExtensionsIds =
      dataExtensions
        ?.filter((dataExtension) => dataExtension.businessUnitId === businessUnitId)
        ?.map((dataExtension) => dataExtension.id) || [];

    const haveAllSelected = unitDataExtensionsIds.every((dataExtension) => salesforce.list.includes(dataExtension));

    if (haveAllSelected) {
      // filter all data extensions from business unit
      salesforce.set((prevState) =>
        prevState.filter((dataExtension) => !unitDataExtensionsIds?.includes(dataExtension)),
      );
      return;
    }

    // filter already selected data extensions from a business unit and then select all from that particular business unit
    salesforce.set((prevState) => {
      const filteredDataExtensions = prevState.filter((project) => !unitDataExtensionsIds?.includes(project));
      return [...filteredDataExtensions, ...unitDataExtensionsIds];
    });
  };

  const projectsEmpty = projects?.length === 0 && !projectsLoading;
  const dataExtensionsEmpty = dataExtensions?.length === 0 && !dataExtensionsLoading;

  return (
    <div className="suppress-details">
      <div>
        <Label text="Projects to suppress" isInputLabel />

        <p className={styles.projectDescription}>Select projects these suppressions will be added to</p>
        <div className={styles.grid}>
          {accounts.map((account) => {
            const selectedAll = allFromAccountSelected(account.id);

            return (
              <button
                key={account.id}
                type="button"
                className={cn(styles.button, { [styles.buttonActive]: selectedAll })}
                onClick={handleSelectFromAccount(account.id)}
              >
                {selectedAll ? <CheckCircle width={14} height={14} /> : <List width={14} height={14} />}
                <TextEllipsis>
                  {selectedAll ? "Selected" : "Select"} all from <strong>{account.name}</strong>
                </TextEllipsis>
              </button>
            );
          })}
        </div>
        <Select
          name="projectIds"
          options={projectsOptions}
          isSearchable
          isMulti
          selectedOptionsKeys={iterable.list}
          onChange={(options) => iterable.set(options.map((item) => item.value))}
          searchPlaceholder="Search projects"
          asyncOptions={{ loading: projectsLoading }}
          disabled={projectsLoading}
        />
      </div>

      {activeEsps?.salesforce && (
        <div className={styles.projectSelect}>
          <Label text="Data extensions to suppress" isInputLabel />

          {dataExtensionsEmpty && (
            <>
              <div>
                <p className={styles.projectDescription}>
                  You currently have no active data extensions. Click the button bellow, select the business unit and
                  activate or create a new data extension.
                </p>

                <Button appearance="primary" onClick={() => navigate(BUSINESS_UNIT_DETAILS_PAGE.path)} btnSize="small">
                  <Plus />
                  Create first data extension
                </Button>
              </div>
            </>
          )}

          {!dataExtensionsEmpty && (
            <>
              <p className={styles.projectDescription}>Select data extensions to which suppresses will be added</p>
              <div className={styles.grid}>
                {businessUnits.map((businessUnit) => {
                  const selectedAll = allFromBusinessUnitSelected(businessUnit.id);

                  return (
                    <button
                      key={businessUnit.id}
                      type="button"
                      className={cn(styles.button, { [styles.buttonActive]: selectedAll })}
                      onClick={handleSelectFromBusinessUnit(businessUnit.id)}
                    >
                      {selectedAll ? <CheckCircle width={14} height={14} /> : <List width={14} height={14} />}
                      <TextEllipsis>
                        {selectedAll ? "Selected" : "Select"} all from <strong>{businessUnit.name}</strong>
                      </TextEllipsis>
                    </button>
                  );
                })}
              </div>

              <Select
                name="dataExtensionIds"
                options={dataExtensionsOptions}
                selectedOptionsKeys={salesforce.list}
                onChange={(options) => salesforce.set(options.map((item) => item.value))}
                isSearchable
                isMulti
                searchPlaceholder="Search data extensions"
                asyncOptions={{ loading: dataExtensionsLoading }}
                disabled={dataExtensionsLoading}
              />
            </>
          )}
        </div>
      )}

      {activeEsps?.remarkety && remarketyAccountsOptions?.length > 0 && (
        <div>
          <Label text="Remarkety accounts to suppress" isInputLabel />
          <p className={styles.projectDescription}>
            Select Remarkety accounts that will be included in the suppression
          </p>

          <Select
            name="remarketyAccountsIds"
            options={remarketyAccountsOptions}
            selectedOptionsKeys={remarkety.list}
            onChange={(options) => remarkety.set(options.map((item) => item.value))}
            isSearchable
            isMulti
            searchPlaceholder="Search Remarkety accounts"
            asyncOptions={{ loading: remarketyAccountsLoading }}
            disabled={remarketyAccountsLoading}
          />
        </div>
      )}

      {activeEsps?.marketo && marketoAccountsOptions?.length > 0 && (
        <div>
          <Label text="Marketo accounts to suppress" isInputLabel />
          <p className={styles.projectDescription}>Select Marketo accounts that will be included in the suppression</p>

          <Select
            name="marketoAccountsIds"
            options={marketoAccountsOptions}
            selectedOptionsKeys={marketo.list}
            onChange={(options) => marketo.set(options.map((item) => item.value))}
            isSearchable
            isMulti
            searchPlaceholder="Search Marketo accounts"
            asyncOptions={{ loading: marketoAccountsLoading }}
            disabled={marketoAccountsLoading}
          />
        </div>
      )}

      {activeEsps?.greenarrow && greenarrowAccountsOptions?.length > 0 && (
        <div>
          <Label text="GreenArrow accounts to suppress" isInputLabel />
          <p className={styles.projectDescription}>
            Select GreenArrow accounts that will be included in the suppression
          </p>

          <Select
            name="greenarrowAccountsIds"
            options={greenarrowAccountsOptions}
            selectedOptionsKeys={greenarrow.list}
            onChange={(options) => greenarrow.set(options.map((item) => item.value))}
            isSearchable
            isMulti
            searchPlaceholder="Search GreenArrow accounts"
            asyncOptions={{ loading: greenarrowAccountsLoading }}
            disabled={greenarrowAccountsLoading}
          />
        </div>
      )}

      {activeEsps?.insider && insiderAccountsOptions?.length > 0 && (
        <div>
          <Label text="Insider accounts to suppress" isInputLabel />
          <p className={styles.projectDescription}>Select Insider accounts that will be included in the suppression</p>

          <Select
            name="insiderAccountsIds"
            options={insiderAccountsOptions}
            selectedOptionsKeys={insider.list}
            onChange={(options) => insider.set(options.map((item) => item.value))}
            isSearchable
            isMulti
            searchPlaceholder="Search Insider accounts"
            asyncOptions={{ loading: insiderAccountsLoading }}
            disabled={insiderAccountsLoading}
          />
        </div>
      )}

      {activeEsps?.blueshift && blueshiftAccountsOptions?.length > 0 && (
        <div>
          <Label text="Blueshift accounts to suppress" isInputLabel />
          <p className={styles.projectDescription}>
            Select Blueshift accounts that will be included in the suppression
          </p>

          <Select
            name="blueshiftAccountIds"
            options={blueshiftAccountsOptions}
            selectedOptionsKeys={blueshift.list}
            onChange={(options) => blueshift.set(options.map((item) => item.value))}
            isSearchable
            isMulti
            searchPlaceholder="Search Blueshift accounts"
            asyncOptions={{ loading: blueshiftAccountsLoading }}
            disabled={blueshiftAccountsLoading}
          />
        </div>
      )}
    </div>
  );
};
