import React, { FC, useState } from "react";
import { useFetch, useSubmit } from "@hyper-fetch/react";
import { Formik, Form, FormikProps } from "formik";
import {
  Button,
  MessageField,
  FormButtons,
  FormField,
  notification,
  Modal,
  Label,
  Switch,
  SelectOption,
} from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { getGreenArrowSuppressionLists, updateGreenArrowAccount, UpdateGreenArrowAccountData } from "api";
import { GreenArrowAccountModel } from "models";
import { validationSchema, initialValues, convertListsToOptions } from "./update-green-arrow.constants";
import { SuppressionLists } from "./lists/suppression-lists";

import styles from "./update-green-arrow.module.scss";

export type UpdateGreenArrowAccountProps = {
  isOpen: boolean;
  close: VoidFunction;
  account: GreenArrowAccountModel;
  onUpdateSuccess: (value: GreenArrowAccountModel) => void;
};

export const UpdateGreenArrowAccount: FC<UpdateGreenArrowAccountProps> = ({
  isOpen,
  account,
  close,
  onUpdateSuccess,
}) => {
  const [clientSecretEnabled, setClientSecretEnabled] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const {
    data,
    onSuccess,
    loading: listsLoading,
  } = useFetch(getGreenArrowSuppressionLists.setParams({ accountId: account.id }));
  onSuccess(({ response }) => {
    setOptions(convertListsToOptions(response));
  });

  const { submit, submitting, error, onSubmitSuccess, onSubmitError } = useSubmit(updateGreenArrowAccount);
  onSubmitSuccess(({ response }) => {
    onUpdateSuccess(response);
    close();
    notification.success("GreenArrow account updated!", "GreenArrow account has been successfully updated.");
  });
  onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the GreenArrow account.");
  });

  useDidUpdate(() => {
    setClientSecretEnabled(false);
  }, [isOpen]);

  const handleSwitchToggle =
    (formikProps: FormikProps<Partial<UpdateGreenArrowAccountData>>) => (value: string, checked: boolean) => {
      setClientSecretEnabled(checked);

      if (checked) {
        // reset values
        formikProps.setFieldValue("apiUrl", "");
        formikProps.setFieldValue("apiKey", "");
        formikProps.setFieldValue("suppressionListId", 0);
        setOptions([]);
      }

      if (!checked) {
        // set initial values - listId/secret/url
        formikProps.setFieldValue("apiUrl", account?.apiUrl || "");
        formikProps.setFieldValue("apiKey", "");
        formikProps.setFieldTouched("apiKey", false, false);
        formikProps.setFieldValue("suppressionListId", account?.suppressionListId || 0);
        if (data) {
          setOptions(convertListsToOptions(data));
        }
      }
    };

  const handleSubmit = async (values: UpdateGreenArrowAccountData) => {
    if (account) {
      const data = { ...values };
      if (!clientSecretEnabled) delete data.apiUrl;
      if (!clientSecretEnabled) delete data.apiKey;

      await submit({
        data,
        params: { accountId: account.id },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik
        initialValues={initialValues(account)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(clientSecretEnabled)}
        enableReinitialize
      >
        {(props) => (
          <Form>
            <div className={styles.title}>Update {account?.name} account</div>
            <p className={styles.description}>If the switch is checked, a new API key and API URL will be required.</p>

            <FormField type="text" name="name" label="Account name" disableFloatingLabel required />

            <div className={styles.labelRow}>
              <Label text="Update credentials" isInputLabel />
              <Switch value="test" disableError checked={clientSecretEnabled} onChange={handleSwitchToggle(props)} />
            </div>

            <div className={styles.row}>
              <FormField
                type="text"
                name="apiKey"
                placeholder={clientSecretEnabled ? "Provide a new API key" : "Use the same API key"}
                disableFloatingLabel
                disabled={!clientSecretEnabled}
              />

              <FormField
                type="text"
                name="apiUrl"
                placeholder={clientSecretEnabled ? "Provide a new URL" : "Use the same URL"}
                disableFloatingLabel
                disabled={!clientSecretEnabled}
              />
            </div>

            <SuppressionLists options={options} setOptions={setOptions} loading={listsLoading} />

            <MessageField message={error?.message || ""} />

            <FormButtons className={styles.buttons}>
              <Button appearance="secondary" onClick={close}>
                Cancel
              </Button>
              <Button type="submit" loading={submitting}>
                Submit
              </Button>
            </FormButtons>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
