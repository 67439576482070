import { FC } from "react";
import { TableRow, TableCell, TextEllipsis, formatDate, Nullable } from "@epcnetwork/core-ui-kit";

import { Progress, JobItemStatus } from "components";
import { AutocleaningJobModel } from "models";

import { Calendar, Clock } from "assets/icons";

import styles from "./autocleaning-item.module.scss";

type UsersListTableRowProps = {
  job: AutocleaningJobModel;
  onErrorBadgeClick?: (error: Nullable<string>) => void;
};

export const AutocleaningItem: FC<UsersListTableRowProps> = ({ job, onErrorBadgeClick }) => {
  const createdAtDate = new Date(job.createdAt);

  return (
    <TableRow id={job.id} className={styles.tableRow}>
      <TableCell className={styles.cellRow}>
        <div className={styles.row}>
          <Calendar />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "EEE, MMM dd, yyyy")}
          </TextEllipsis>
        </div>
        <div className={styles.row}>
          <Clock />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "HH:mm:ss")}
          </TextEllipsis>
        </div>
      </TableCell>

      <TableCell>
        {job.status === "processing" && job?.valuesCount ? (
          <Progress current={job.currentValuesCount} total={job.valuesCount} />
        ) : (
          <JobItemStatus
            status={job.status}
            valuesCount={job?.valuesCount || 0}
            successCount={job?.currentValuesCount}
            onErrorBadgeClick={onErrorBadgeClick}
            message={job?.error}
          />
        )}
      </TableCell>

      <TableCell>
        <div />
      </TableCell>
    </TableRow>
  );
};
