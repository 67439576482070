import React, { FC } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { Button, MessageField, FormField, notification, Modal, Label } from "@epcnetwork/core-ui-kit";
import { useParams } from "react-router-dom";

import { createOffer } from "api";
import { CreateOptizmoOfferProps, CreateOptizmoOfferFormData } from "./create-optizmo-offer.types";
import { validationSchema, initialValues } from "./create-optizmo-offer.constants";

import styles from "../create-optizmo.module.scss";

export const CreateOptizmoOffer: FC<CreateOptizmoOfferProps> = ({ isOpen, close, onCreateSuccess, company }) => {
  const { clientId } = useParams<{ clientId: string }>();

  const { submit, error, onSubmitSuccess, onSubmitError, submitting } = useSubmit(createOffer);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    close();
    notification.success("Optizmo offer created!", "Optizmo offer has been successfully created.");
  });
  onSubmitError(() => {
    notification.error("Create error!", "Error occurred while creating a new Optizmo offer.");
  });

  const handleClose = () => {
    close();
  };

  const handleSubmit = async (values: CreateOptizmoOfferFormData) => {
    if (clientId) {
      const { accessKey, ...rest } = values;

      if (company?.optizmo) {
        const data = { ...values, clientId: +clientId };
        if (!data?.accessKey) delete data.accessKey;

        await submit({ data });
      } else {
        const data = { ...rest, clientId: +clientId };
        await submit({ data });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isValid }) => {
          return (
            <Form>
              <div className={styles.title}>Add offer</div>
              <MessageField message={error?.message || ""} />

              <FormField type="text" name="name" label="Offer name" disableFloatingLabel required />

              <div>
                <div style={{ marginBottom: "8px" }}>
                  <Label text="Access key" isInputLabel />
                  {!company?.optizmo && (
                    <p className={styles.projectDescription}>
                      Company is not configured to use Optizmo and you cannot set an access key.
                    </p>
                  )}
                </div>
                <FormField
                  type="text"
                  name="accessKey"
                  label=""
                  placeholder="Access key"
                  disableFloatingLabel
                  disabled={!company?.optizmo}
                />
              </div>

              <div className={styles.buttons}>
                <Button appearance="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!isValid} loading={submitting}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
