import React from "react";
import { FormField, notification, SelectOption } from "@epcnetwork/core-ui-kit";
import { DownloadIcon } from "@radix-ui/react-icons";
import { useSubmit } from "@hyper-fetch/react";
import { useFormikContext } from "formik";

import { getGreenArrowSuppressionListsWithCredentials, UpdateGreenArrowAccountData } from "api";

import styles from "../update-green-arrow.module.scss";

type Props = {
  options: SelectOption[];
  setOptions: (options: SelectOption[]) => void;
  loading: boolean;
};

export const SuppressionLists: React.FC<Props> = ({ options, setOptions, loading }) => {
  const { values } = useFormikContext<UpdateGreenArrowAccountData>();

  const suppressionLists = useSubmit(getGreenArrowSuppressionListsWithCredentials);
  suppressionLists.onSubmitSuccess(({ response }) => {
    const mappedOptions = response.map((item) => ({ label: `${item.name} (ID: ${item.id})`, value: item.id }));
    setOptions(mappedOptions);
  });
  suppressionLists.onSubmitError(() => {
    notification.error("Could not fetch a suppression lists", "Check if provided credentials are correct.");
  });

  const handleListsFetch = async () => {
    if (values.apiKey && values.apiUrl) {
      await suppressionLists.submit({ data: { apiKey: values.apiKey, apiUrl: values.apiUrl } });
    }
  };

  return (
    <div className={styles.inputRow}>
      <FormField
        name="suppressionListId"
        label="Suppression list"
        disableFloatingLabel
        type="select"
        asyncOptions={{ loading: suppressionLists.submitting || loading }}
        options={options}
      />
      <button
        type="button"
        className={styles.downloadButton}
        disabled={!values.apiKey || !values.apiUrl}
        onClick={handleListsFetch}
      >
        <DownloadIcon />
      </button>
    </div>
  );
};
