import { object, string, Schema } from "yup";

import { BlueshiftAccountData } from "api";

export const validationSchema: Schema<BlueshiftAccountData> = object().shape({
  name: string().required("Name is required"),
  apiKey: string().required("API key is required"),
});

export const initialValues: BlueshiftAccountData = {
  name: "",
  apiKey: "",
};
