import React, { useState } from "react";

import { Container, TabOption, Tabs } from "components";
import { UnsubFromFile } from "./unsub-from-file/unsub-from-file";
import { UnsubManually } from "./unsub-manually/unsub-manually";
import { useTour } from "hooks";
import { steps } from "./create-unsub.tour";

import styles from "./create-unsub.module.scss";

type Method = "manual" | "file";

export const CreateUnsubPage: React.FC = () => {
  useTour({
    name: "unsubscribe-page",
    steps,
  });

  const [method, setMethod] = useState<Method>("manual");

  const modeOptions: TabOption<Method>[] = [
    { value: "manual", label: "Manual", className: "manual-tab" },
    { value: "file", label: "File", className: "file-tab" },
  ];

  const handleRangeChange = (option: TabOption<Method>) => {
    setMethod(option.value);
  };

  return (
    <Container className={styles.wrapper}>
      <div className={styles.tabsWrapper}>
        <Tabs options={modeOptions} selected={method} onChange={handleRangeChange} className={styles.tabs} />
      </div>
      <div className={styles.container}>
        {method === "manual" && <UnsubManually />}
        {method === "file" && <UnsubFromFile />}
      </div>
    </Container>
  );
};
