import { object, string, Schema } from "yup";

import { BlueshiftAccountData } from "api";
import { BlueshiftAccountModel } from "models";

export const validationSchema = (apiKeyEnabled: boolean): Schema<BlueshiftAccountData> =>
  object().shape({
    name: string().required("Name is required"),
    apiKey: apiKeyEnabled ? string().required("API key is required") : string(),
  });

export const initialValues = (account: BlueshiftAccountModel | null): BlueshiftAccountData => ({
  name: account?.name || "",
  apiKey: account?.apiKey || "",
});
