import { object, string, Schema } from "yup";

import { UpdateRemarketyAccountData } from "api";
import { RemarketyAccountModel } from "models";

export const validationSchema = (clientSecretEnabled: boolean): Schema<UpdateRemarketyAccountData> =>
  object().shape({
    name: string().required("Name is required"),
    storeId: string().required("Store ID is required"),
    apiKey: clientSecretEnabled ? string().required("Api key is required") : string(),
  });

export const initialValues = (account: RemarketyAccountModel | null): UpdateRemarketyAccountData => ({
  name: account?.name || "",
  apiKey: account?.apiKey || "",
  storeId: account?.storeId || "",
});
