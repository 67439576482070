import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Insider accounts</h2>
    <p>
      This feature is designed exclusively for admins to manage Insider accounts and streamline email marketing
      automation.
      <br />
      <br />
      As an admin, you have the authority to add new Insider accounts, update existing ones, and delete.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".navbar", on: "bottom-start" },
    permission: ["read", "integrations"],
  },
];
