import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Autocleaning jobs page</h2>
    <p>
      Efficiently manage and monitor the automated email cleaning processes across all active projects. This page
      provides insights into scheduled and completed tasks executed by the cron job system, ensuring your email lists
      remain up-to-date and optimized.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Autocleaning jobs items</h2>

    <p>
      Review detailed logs of email cleaning jobs, including execution dates, completion status, and outcomes. Stay
      informed about the health and performance of your email cleaning operations at a glance.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".autocleaning-navbar", on: "bottom-start" },
    permission: ["read", "jobs"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "jobs"],
  },
];
