import { client } from "api/client.api";
import { fileUploadMiddleware } from "api/file-upload.api.middleware";
import { ManualUnsubscribeData, UnsubscribeData } from "./unsubscribe.types";

export const createUnsubscribe = client.createRequest<null, ManualUnsubscribeData>()({
  method: "POST",
  endpoint: "/unsubscribe",
  options: {
    timeout: 0,
  },
});

export const unsubscribeFromFile = fileUploadMiddleware<undefined, UnsubscribeData>()({
  method: "post",
  endpoint: "/unsubscribe/form",
  isFormData: true,
});
