import React, { useState } from "react";
import { useQuery } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";
import cn from "classnames";
import { useFetch } from "@hyper-fetch/react";

import { Tabs, TabOption } from "components";
import { Integration } from "types";
import { IterableTable } from "./iterable-table/iterable-table";
import { SalesforceTable } from "./salesforce-table/salesforce-table";
import { RemarketyTable } from "./remarkety-table/remarkety-table";
import { MarketoTable } from "./marketo-table/marketo-table";
import { InsiderTable } from "./insider-table/insider-table";
import { GreenArrowTable } from "./green-arrow-table/green-arrow-table";
import { BlueshiftTable } from "./blueshift-table/blueshift-table";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "constants/query.constants";
import { DashboardSocketInstance } from "pages/dashboard/dashboard.types";
import { getActiveEsps } from "api";
import { Blueshift, GreenArrow, Insider, Iterable, Marketo, Remarkety, Salesforce } from "assets";

import styles from "./suppressions-status.module.scss";

type Props = {
  socket: DashboardSocketInstance | null;
};

export const SuppressionsStatus: React.FC<Props> = ({ socket }) => {
  const { setQueryParams } = useQuery();

  const [selectedIntegration, setSelectedIntegration] = useState<Integration>("iterable");

  const { data: activeEsps, onSuccess } = useFetch(getActiveEsps);
  onSuccess(({ response }) => {
    const firstActive = Object.entries(response).find(([key, value]) => value)?.[0];
    if (firstActive) setSelectedIntegration(firstActive as Integration);
  });

  useDidUpdate(() => {
    setQueryParams({ search: "", offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT, order: "" });
  }, [selectedIntegration]);

  const handleIntegrationChange = (option: TabOption<Integration>) => {
    setSelectedIntegration(option.value);
  };

  const title: Record<Integration, string> = {
    iterable: "Iterable projects",
    salesforce: "Salesforce data extensions",
    remarkety: "Remarkety accounts",
    marketo: "Marketo accounts",
    greenarrow: "GreenArrow accounts",
    insider: "Insider accounts",
    blueshift: "Blueshift accounts",
  };

  const integrationOptions: (TabOption<Integration> & { espActive: boolean })[] = [
    { value: "iterable", label: "Iterable", icon: <Iterable />, espActive: activeEsps?.iterable || false },
    { value: "salesforce", label: "Salesforce", icon: <Salesforce />, espActive: activeEsps?.salesforce || false },
    { value: "remarkety", label: "Remarkety", icon: <Remarkety />, espActive: activeEsps?.remarkety || false },
    { value: "marketo", label: "Marketo", icon: <Marketo />, espActive: activeEsps?.marketo || false },
    {
      value: "greenarrow",
      label: "GreenArrow",
      icon: <GreenArrow style={{ flexShrink: 0, width: "15px" }} />,
      espActive: activeEsps?.greenarrow || false,
    },
    { value: "insider", label: "Insider", icon: <Insider />, espActive: activeEsps?.insider || false },
    { value: "blueshift", label: "Blueshift", icon: <Blueshift />, espActive: activeEsps?.blueshift || false },
  ];

  const activeIntegrationOptions = integrationOptions.filter((option) => option.espActive);

  return (
    <div className={cn(styles.container, "dashboard-suppressions-statuses")}>
      <div>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <span className={styles.label}>Suppressions status by {title[selectedIntegration]}</span>
            <span className={styles.subtitle}>in the last 24 hours</span>
          </div>

          <Tabs
            options={activeIntegrationOptions}
            selected={selectedIntegration}
            onChange={handleIntegrationChange}
            className={cn(styles.tabs, "dashboard-status-tabs")}
          />
        </div>

        <div className={styles.content}>
          {selectedIntegration === "iterable" && <IterableTable socket={socket} />}
          {selectedIntegration === "salesforce" && <SalesforceTable socket={socket} />}
          {selectedIntegration === "remarkety" && <RemarketyTable socket={socket} />}
          {selectedIntegration === "marketo" && <MarketoTable socket={socket} />}
          {selectedIntegration === "greenarrow" && <GreenArrowTable socket={socket} />}
          {selectedIntegration === "insider" && <InsiderTable socket={socket} />}
          {selectedIntegration === "blueshift" && <BlueshiftTable socket={socket} />}
        </div>
      </div>
    </div>
  );
};
