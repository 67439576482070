import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Unsubscribe job details</h2>
    <p>
      A detailed view of a specific unsubscribe job, showcasing a list of unsubscribed emails, associated list details,
      and the current status of each operation for transparency and debugging.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    permission: ["read", "unsub-jobs"],
  },
];
