import React from "react";
import { formatDate, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { Skeleton } from "components";
import { Navbar } from "components/layout/navbar/navbar";
import { useSidebar } from "hooks";
import { UnsubscribeJobModel } from "models";
import { Calendar, Clock, User } from "assets";
import { UNSUBSCRIBE_JOBS_LIST_PAGE } from "constants/routes.constants";

import styles from "./job-details-header.module.scss";

interface Props {
  job: UnsubscribeJobModel | null;
  loading: boolean;
}

export const JobDetailsHeader: React.FC<Props> = ({ job, loading }) => {
  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  const createdAtDate = new Date(job?.createdAt || "");

  return (
    <Navbar
      isMenuCollapsed={isMenuCollapsed}
      toggleSidebar={toggleSidebar}
      name="Unsubscribe job details"
      loading={loading}
      className={styles.navbar}
      backPage={UNSUBSCRIBE_JOBS_LIST_PAGE.path}
      navbarClassName="job-details-navbar"
    >
      <div className={styles.details}>
        <>
          {loading && <Skeleton width="120px" height="16px" />}
          {!loading && (
            <div className={styles.rowWrapper}>
              Creator:
              <div className={styles.row}>
                <User />
                <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
                  {job?.email}
                </TextEllipsis>
              </div>
            </div>
          )}
        </>
        <>
          {loading && <Skeleton width="120px" height="16px" />}
          {!loading && (
            <div className={styles.rowWrapper}>
              Created at:
              <div className={styles.row}>
                <Calendar />
                <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
                  {formatDate(createdAtDate, "EEE, MMM dd, yyyy")}
                </TextEllipsis>
              </div>
              <div className={styles.row}>
                <Clock />
                <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
                  {formatDate(createdAtDate, "HH:mm:ss")}
                </TextEllipsis>
              </div>
            </div>
          )}
        </>
      </div>
    </Navbar>
  );
};
