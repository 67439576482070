import { object, string, Schema } from "yup";

import { RemarketyAccountData } from "api";

export const validationSchema: Schema<RemarketyAccountData> = object().shape({
  name: string().required("Name is required"),
  storeId: string().required("Store ID is required"),
  apiKey: string().required("API key is required"),
});

export const initialValues: RemarketyAccountData = {
  name: "",
  storeId: "",
  apiKey: "",
};
