import React, { useState } from "react";
import { useFetch } from "@hyper-fetch/react";
import { useParams } from "react-router-dom";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { Container, Loader } from "components";
import { getJob } from "api";
import { JobDetailsHeader } from "./job-details-header/job-details-header";
import { SuppressionValues } from "./suppression-values/suppression-values";
import { JobDetailsProgressUpdate, JobDetailsUpdate, useJobSocketHook } from "hooks/use-job-socket";
import { JobModel } from "models";
import { steps } from "./job-details.tour";
import { useTour } from "hooks";

import styles from "./job-details.module.scss";

export const JobDetailsPage: React.FC = () => {
  useTour({
    name: "job-details",
    steps,
  });

  const { jobId = "" } = useParams<{ jobId: string }>();
  const { socket } = useJobSocketHook({ jobId });

  const [job, setJob] = useState<JobModel | null>(null);

  const { loading, refetch, onSuccess } = useFetch(getJob.setParams({ jobId: jobId || "" }));
  onSuccess(({ response }) => setJob(response));

  useDidUpdate(
    () => {
      const handleJobDetailsUpdate = ({
        suppressionJobId,
        status,
        finishedCount,
      }: JobDetailsUpdate | JobDetailsProgressUpdate) => {
        setJob((prevState) => {
          if (!prevState) return null;
          if (status) return { ...prevState, status };
          if (finishedCount) return { ...prevState, finishedCount };
          return prevState;
        });
      };

      if (socket) {
        socket.on("roomJobUpdate", handleJobDetailsUpdate);

        return () => {
          socket.off("roomJobUpdate", handleJobDetailsUpdate);
        };
      }
    },
    [socket],
    true,
  );

  return (
    <>
      {loading && <Loader />}
      {!loading && job && (
        <>
          <JobDetailsHeader job={job} loading={loading} refresh={refetch} />
          <Container className={styles.container}>
            <SuppressionValues job={job} socket={socket} />
          </Container>
        </>
      )}
    </>
  );
};
