import React from "react";
import { Drawer, Nullable } from "@epcnetwork/core-ui-kit";

import { JobItemStatus } from "components";

import styles from "./autocleaning-error-drawer.module.scss";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  errorToPreview?: Nullable<string>;
}

export const AutocleaningErrorDrawer: React.FC<Props> = ({ isOpen, handleClose, errorToPreview }) => {
  return (
    <Drawer isOpen={isOpen} setClose={handleClose} contentClassName={styles.drawer}>
      {errorToPreview && (
        <div className={styles.container}>
          <JobItemStatus status="failed" />
          <div className={styles.jsonWrapper}>
            <pre className={styles.json}>{errorToPreview}</pre>
          </div>
        </div>
      )}
    </Drawer>
  );
};
