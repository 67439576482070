import { object, Schema } from "yup";

import { espUnsubValidationSchema, initialEspValues } from "../create-unsub.constants";
import { UnsubscribeFormData } from "api";

export const initialValues: UnsubscribeFormData = {
  data: initialEspValues,
};

export const validationSchema: Schema<UnsubscribeFormData> = object().shape({
  data: espUnsubValidationSchema,
});
