import { io, Socket } from "socket.io-client";
import { useSelector } from "react-redux";
import { useState } from "react";
import { notification, useDidMount } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { appEnvironment } from "config/environment.config";
import { BaseListeners, SocketInstance, SocketListenerObject, SocketArguments } from "./use-socket.types";

export const useSocket = <T extends SocketListenerObject>({ namespace }: SocketArguments) => {
  const [socket, setSocket] = useState<SocketInstance<T> | null>(null);
  const [isConnected, setConnected] = useState<boolean>(false);

  const { token } = useSelector((state: RootState) => state.auth);

  const listenerHandlers: BaseListeners = {
    connect: () => {
      setConnected(true);
    },
    connectError: () => {
      setConnected(false);
    },
    disconnect: () => {
      setConnected(false);
    },
    exception: (error: Error) => {
      notification.error("Socket exception", "Error while connecting to the socket. " + error);
    },
  };

  const addJobListener =
    (socketInstance: Socket<BaseListeners>) =>
    <E extends keyof BaseListeners>(event: E) => {
      socketInstance.on<keyof BaseListeners>(event, listenerHandlers[event]);

      return () => {
        socketInstance.off<keyof BaseListeners>(event, listenerHandlers[event]);
      };
    };

  const mountListeners = (socketInstance: Socket<BaseListeners>) => {
    const connectUnmount = addJobListener(socketInstance)("connect");
    const connectErrorUnmount = addJobListener(socketInstance)("connectError");
    const disconnectUnmount = addJobListener(socketInstance)("disconnect");
    const exceptionUnmount = addJobListener(socketInstance)("exception");

    return () => {
      connectUnmount();
      connectErrorUnmount();
      disconnectUnmount();
      exceptionUnmount();
    };
  };

  useDidMount(() => {
    const socketInstance: Socket<BaseListeners & Partial<T>> = io(appEnvironment.apiUrl + namespace, {
      autoConnect: false,
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
    });

    const unmountListeners = mountListeners(socketInstance);
    socketInstance.connect();

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
      unmountListeners();
    };
  });

  return { socket, isConnected };
};
