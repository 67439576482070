import React, { useRef, useState } from "react";
import { useFetch } from "@hyper-fetch/react";
import { Nullable, SelectOption, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { Container, StatusFilter, TableError, TableNoContent } from "components";
import { useSocket, useTablePagination, useTour } from "hooks";
import { AutocleaningJobsQuery, getAutocleaningJobs } from "api";
import { AutocleaningItem } from "./table-item/autocleaning-item";
import { getInitialStorageFilters } from "utils";
import { AutocleaningJobListListenerHandlers, AutocleaningJobUpdateData } from "./autocleaning-list.types";
import { initialFilters, autocleaningListColumns, TABLE_NAME } from "./autocleaning-list.constants";
import { AutocleaningJobModel, List } from "models";
import { steps } from "./autocleaning-list.tour";
import { AutocleaningErrorDrawer } from "./error-drawer/autocleaning-error-drawer";

import styles from "./autocleaning-list.module.scss";

export const AutocleaningListPage: React.FC = () => {
  useTour({
    name: "autocleaning-list",
    steps,
  });

  const pageSizeRef = useRef<number>(10);

  const { socket } = useSocket<AutocleaningJobListListenerHandlers>({ namespace: "/auto-suppressions" });
  const { query, setQueryParams } = useFilters<AutocleaningJobsQuery>(
    getInitialStorageFilters<AutocleaningJobsQuery>(TABLE_NAME, initialFilters),
  );

  const [errorToPreview, setErrorToPreview] = useState<Nullable<string>>(null);
  const [jobs, setJobs] = useState<List<AutocleaningJobModel> | null>(null);

  const { loading, refetch, error, onSuccess } = useFetch(getAutocleaningJobs.setQueryParams(query));
  onSuccess(({ response }) => setJobs(response));

  const pagination = usePagination({ listPayload: jobs });
  const onElementsPerPageChange = (value: number) => {
    pagination.onElementsPerPageChange(value);
    pageSizeRef.current = value;
  };
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  useDidUpdate(
    () => {
      const handleJobCreate = (job: AutocleaningJobModel) => {
        setJobs((prevState) => {
          if (!prevState) return null;

          let previousJobs = prevState.data;
          if (prevState.data.length >= pageSizeRef.current) {
            previousJobs = prevState.data.slice(0, -1);
          }

          const newJobs = [job, ...previousJobs];
          return { ...prevState, data: newJobs, total: prevState.total + 1 };
        });
      };

      const handleJobListUpdate = ({
        id,
        status,
        currentValuesCount,
        valuesCount,
        error,
      }: AutocleaningJobUpdateData) => {
        setJobs((prevState) => {
          if (!prevState) return null;

          const updatedState = prevState.data.map((job) => {
            if (job.id === id) {
              let updatedJob = { ...job };
              if (status) updatedJob = { ...job, status };
              if (valuesCount) updatedJob = { ...updatedJob, valuesCount: valuesCount || 0 };
              if (error) updatedJob = { ...updatedJob, error };
              if (currentValuesCount !== undefined) updatedJob = { ...updatedJob, currentValuesCount };
              return updatedJob;
            }

            return job;
          });

          return { ...prevState, data: updatedState };
        });
      };

      if (socket) {
        socket.on("jobUpdate", handleJobListUpdate);
        socket.on("jobCreate", handleJobCreate);

        return () => {
          socket.off("jobUpdate", handleJobListUpdate);
          socket.off("jobCreate", handleJobCreate);
        };
      }
    },
    [socket],
    true,
  );

  const handleStatusChange = (value: SelectOption<string> | null) => {
    if (value) {
      setQueryParams({ ...query, limit: "50", offset: "0", status: value.value });
    }
  };

  return (
    <Container>
      <div className={styles.header}>
        <div />

        <StatusFilter type="autocleaning" selectedStatus={query?.status || ""} onChange={handleStatusChange} />
      </div>

      <Table
        entityName={TABLE_NAME}
        columns={autocleaningListColumns}
        list={jobs?.data}
        error={error?.message}
        refresh={() => refetch()}
        loading={loading}
        resetColumnsOnMount={false}
        isTabTable
        customNoContent={<TableNoContent withBackground />}
        customError={<TableError description={error?.message} withBackground />}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
        row={(job) => <AutocleaningItem job={job} onErrorBadgeClick={(error) => setErrorToPreview(error)} />}
      />

      <AutocleaningErrorDrawer
        isOpen={!!errorToPreview}
        handleClose={() => setErrorToPreview(null)}
        errorToPreview={errorToPreview}
      />
    </Container>
  );
};
