import { object, string, Schema } from "yup";

import { MarketoAccountData } from "api";
import { MARKETO_ENDPOINT_REGEX } from "constants/regex.constants";

export const validationSchema: Schema<MarketoAccountData> = object().shape({
  name: string().required("Name is required"),
  clientId: string().required("Client ID is required"),
  clientSecret: string().required("Client secret is required"),
  endpoint: string()
    .matches(MARKETO_ENDPOINT_REGEX, "Endpoint must be in a XXX-XXX-XXX format")
    .required("Endpoint is required"),
});

export const initialValues: MarketoAccountData = {
  name: "",
  clientId: "",
  clientSecret: "",
  endpoint: "",
};
