import { object, string, Schema } from "yup";

import { InsiderAccountData } from "api";

export const validationSchema: Schema<InsiderAccountData> = object().shape({
  name: string().required("Name is required"),
  partnerName: string().required("Partner name is required"),
  apiKey: string().required("API key is required"),
});

export const initialValues: InsiderAccountData = {
  name: "",
  partnerName: "",
  apiKey: "",
};
