import React, { useState } from "react";
import { Button, Label, Select, SelectOption } from "@epcnetwork/core-ui-kit";
import { useFormikContext } from "formik";
import { useSubmit } from "@hyper-fetch/react";
import { useDidMount } from "@better-hooks/lifecycle";

import { ExportData } from "../export.types";
import { getActiveClients, getActiveCompanies, getActiveOffers } from "api";

import styles from "../export.module.scss";

type Props = {
  submitting: boolean;
};

export const OptizmoSelector: React.FC<Props> = ({ submitting }) => {
  const { values, isSubmitting, isValid, setFieldValue, setFieldTouched } = useFormikContext<ExportData>();

  const [companiesOptions, setCompaniesOptions] = useState<SelectOption<number>[]>([]);
  const [clientsOptions, setClientsOptions] = useState<SelectOption<number>[]>([]);
  const [offersOptions, setOffersOptions] = useState<SelectOption<number>[]>([]);

  const activeCompanies = useSubmit(getActiveCompanies);
  activeCompanies.onSubmitSuccess(async ({ response }) => {
    const options: SelectOption<number>[] = response.map((company) => ({
      label: company.name,
      value: company.id,
    }));
    setCompaniesOptions(options);

    const ids = options.map((option) => option.value);
    await setFieldValue("companyIds", ids);
    setTimeout(async () => await setFieldTouched("companyIds", true), 0);
  });

  const activeClients = useSubmit(getActiveClients);
  activeClients.onSubmitSuccess(async ({ response }) => {
    const options: SelectOption<number>[] = response.map((client) => ({
      label: client.name,
      value: client.id,
    }));
    setClientsOptions(options);

    const ids = options.map((option) => option.value);
    await setFieldValue("clientIds", ids);
    setTimeout(async () => await setFieldTouched("clientIds", true), 0);
  });

  const activeOffers = useSubmit(getActiveOffers);
  activeOffers.onSubmitSuccess(async ({ response }) => {
    const options: SelectOption<number>[] = response.map((offer) => ({
      label: offer.name,
      value: offer.id,
      locked: !offer?.isInitialized,
    }));
    setOffersOptions(options);

    const ids = options.filter((option) => !option.locked).map((option) => option.value);
    await setFieldValue("offerIds", ids);
    setTimeout(async () => await setFieldTouched("offerIds", true), 0);
  });

  useDidMount(() => {
    activeCompanies.submit();
    activeClients.submit();
    activeOffers.submit({ queryParams: { optizmo: "true" } });
  });

  const setAllAvailableOffers = async (clientIds: number[]) => {
    const availableOffers = activeOffers.data?.filter((offer) => clientIds.includes(offer.clientId)) || [];
    const offerOptions = availableOffers.map((offer) => ({
      label: offer.name,
      value: offer.id,
      locked: !offer?.isInitialized,
    }));
    const offerIds = availableOffers.filter((offer) => offer.isInitialized).map((offer) => offer.id);
    setOffersOptions(offerOptions);
    await setFieldValue("offerIds", offerIds);
    setTimeout(async () => await setFieldTouched("offerIds", true), 0);
  };

  const handleCompanyChange = async (options: SelectOption<number>[]) => {
    const companyIds = options.map((option) => option.value);
    await setFieldValue("companyIds", companyIds);
    setTimeout(async () => await setFieldTouched("companyIds", true), 0);

    const availableClients = activeClients.data?.filter((client) => companyIds.includes(client.companyId)) || [];
    const clientOptions = availableClients.map((client) => ({ label: client.name, value: client.id }));
    const clientIds = availableClients.map((client) => client.id);
    setClientsOptions(clientOptions);
    await setFieldValue("clientIds", clientIds);
    setTimeout(async () => await setFieldTouched("clientIds", true), 0);

    await setAllAvailableOffers(clientIds);
  };

  const handleClientChange = async (options: SelectOption<number>[]) => {
    const clientIds = options.map((option) => option.value);
    await setFieldValue("clientIds", clientIds);
    setTimeout(async () => await setFieldTouched("clientIds", true), 0);

    await setAllAvailableOffers(clientIds);
  };

  const handleOfferChange = async (options: SelectOption<number>[]) => {
    const offerIds = options.filter((option) => !option.locked).map((option) => option.value);
    await setFieldValue("offerIds", offerIds);
    setTimeout(async () => await setFieldTouched("offerIds", true), 0);
  };

  return (
    <div className={`${styles.container} offer-selector`}>
      <div className={styles.companies}>
        <div className={styles.row}>
          <div className={styles.step}>1</div>
          <div>
            <Label text="Company" isInputLabel />
            <p className={styles.projectDescription}>Select a company</p>
          </div>
        </div>
        <Select
          name="companyIds"
          options={companiesOptions}
          selectedOptionsKeys={values.companyIds}
          onChange={handleCompanyChange}
          isSearchable
          asyncOptions={{
            loading: activeCompanies.submitting,
          }}
          searchPlaceholder="Search projects"
          isMulti
        />
      </div>

      <div className={styles.clients}>
        <div className={styles.row}>
          <div className={styles.step}>2</div>
          <div>
            <Label text="Client" isInputLabel />
            <p className={styles.projectDescription}>Select a client</p>
          </div>
        </div>
        <Select
          name="clientIds"
          options={clientsOptions}
          isSearchable
          selectedOptionsKeys={values.clientIds}
          onChange={handleClientChange}
          searchPlaceholder="Search clients"
          disabled={!values.companyIds.length || activeCompanies.submitting}
          asyncOptions={{
            loading: activeClients.submitting,
          }}
          isMulti
        />
      </div>

      <div className={styles.offers}>
        <div className={styles.row}>
          <div className={styles.step}>3</div>
          <div>
            <Label text="Offer" isInputLabel />
            <p className={styles.projectDescription}>Select an offer</p>
          </div>
        </div>
        <Select
          name="offerIds"
          options={offersOptions}
          isSearchable
          selectedOptionsKeys={values.offerIds}
          onChange={handleOfferChange}
          asyncOptions={{
            loading: activeOffers.submitting,
          }}
          disabled={!values.clientIds.length || activeCompanies.submitting || activeClients.submitting}
          searchPlaceholder="Search offers"
          isMulti
        />
      </div>

      <div className={styles.buttons}>
        <Button type="submit" disabled={!isValid || isSubmitting} loading={submitting}>
          Submit
        </Button>
      </div>
    </div>
  );
};
