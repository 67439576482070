import { FC } from "react";
import { TableRow, TableCell, TextEllipsis, Nullable } from "@epcnetwork/core-ui-kit";

import { SuppressionDetail } from "api";
import { Badge, JobItemStatus } from "components";
import { Integration } from "types";

import styles from "./suppress-details-item.module.scss";

type SuppressionValueProps = {
  index: number;
  value: SuppressionDetail;
  integration: Integration;
  onErrorBadgeClick?: (error: Nullable<string>) => void;
};

export const SuppressDetailsItem: FC<SuppressionValueProps> = ({ index, value, integration, onErrorBadgeClick }) => {
  return (
    <TableRow id={index} className={styles.row}>
      <TableCell className={styles.name}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {value.name}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <Badge>{integration}</Badge>
      </TableCell>

      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          <JobItemStatus status={value.status} message={value?.error} onErrorBadgeClick={onErrorBadgeClick} />
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <></>
      </TableCell>
    </TableRow>
  );
};
