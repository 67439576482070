import { SelectOption } from "@epcnetwork/core-ui-kit";

import {
  AutocleaningJobStatus,
  JobStatus,
  SuppressionProjectOrDataExtensionStatus,
  SuppressionValueStatus,
} from "models";

export const jobStatusOptions: SelectOption<JobStatus | "">[] = [
  { label: "All", value: "" },
  { label: "Created", value: "created" },
  { label: "In progress", value: "inProgress" },
  { label: "Finished", value: "finished" },
  { label: "Aborting", value: "aborting" },
  { label: "Aborted", value: "aborted" },
  { label: "Failed", value: "failed" },
  { label: "Warning", value: "warning" },
];

export const valueStatusOptions: SelectOption<SuppressionValueStatus | "">[] = [
  { label: "All", value: "" },
  { label: "Created", value: "created" },
  { label: "In progress", value: "inProgress" },
  { label: "Warning", value: "warning" },
  { label: "Finished", value: "finished" },
  { label: "Aborted", value: "aborted" },
  { label: "Failed", value: "failed" },
];

export const espStatusOptions: SelectOption<SuppressionProjectOrDataExtensionStatus | "">[] = [
  { label: "All", value: "" },
  { label: "Created", value: "created" },
  { label: "In progress", value: "inProgress" },
  { label: "Success", value: "success" },
  { label: "Aborted", value: "aborted" },
  { label: "Failed", value: "failed" },
];

export const autocleaningStatusOptions: SelectOption<AutocleaningJobStatus | "">[] = [
  { label: "All", value: "" },
  { label: "Exporting", value: "exporting" },
  { label: "Processing", value: "processing" },
  { label: "Forwarding", value: "forwarding" },
  { label: "Done", value: "done" },
  { label: "Failed", value: "failed" },
];

export type ValueType = "job" | "values" | "esp" | "autocleaning";

export const options: Record<ValueType, SelectOption<string>[]> = {
  job: jobStatusOptions,
  values: valueStatusOptions,
  esp: espStatusOptions,
  autocleaning: autocleaningStatusOptions,
};
