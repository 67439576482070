import { FC, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Button, Modal, MessageField, FormButtons, useFileParser } from "@epcnetwork/core-ui-kit";

import { FormField as LocalFormField } from "components";
import { UnsubFileItem } from "../unsub-from-file/unsub-from-file.types";
import { validationSchema } from "./unsub-values-modal.constants";
import { UnsubValues } from "./unsub-values.types";

import styles from "../create-unsub.module.scss";

type SuppressionModalFormProps = {
  file: UnsubFileItem;
  onCloseClick: VoidFunction;
  onSubmitClick: (fileValues: UnsubFileItem) => void;
};

export const UnsubValuesModal: FC<SuppressionModalFormProps> = ({ file, onCloseClick, onSubmitClick }) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);

  const [initialValues] = useState<UnsubValues>({
    headerValues: {
      hasHeaders: file.data.hasHeaders,
      emailIndex: file.data.emailIndex,
      headers: file.data.headers,
    },
  });

  const onSubmit = (values: UnsubValues, { setSubmitting }: FormikHelpers<UnsubValues>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...values,
        ...file.data,
        ...values.headerValues,
      },
    });
    setSubmitting(false);
  };

  return (
    <Modal
      isOpen={Boolean(file)}
      setClose={onCloseClick}
      contentClassName={`${styles.modal} suppressions-modal-content`}
    >
      <h2 className={styles.title}>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
      >
        <Form>
          <LocalFormField
            type="file-table"
            name="headerValues"
            label="Select Email column"
            parsedFile={parsedFile}
            loading={loading}
            selector="radio"
            autocompleteColumns={false}
          />
          <FormButtons>
            <Button appearance="secondary" onClick={onCloseClick}>
              Cancel
            </Button>
            <Button type="submit">Set values</Button>
          </FormButtons>
        </Form>
      </Formik>
    </Modal>
  );
};
