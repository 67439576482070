import { FC } from "react";
import { TableRow, TableCell, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { JobSuppression } from "api";
import { JobItemStatus } from "components";

import styles from "./suppression-value-item.module.scss";

type SuppressionValueProps = {
  index: number;
  value: JobSuppression;
  onRowClick: (value: JobSuppression) => void;
};

export const SuppressionValueItem: FC<SuppressionValueProps> = ({ index, value, onRowClick }) => {
  const handleRowClick = () => {
    onRowClick(value);
  };

  return (
    <TableRow id={index} className={styles.row} onRowClick={handleRowClick}>
      <TableCell className={styles.name}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {value.value}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          <JobItemStatus status={value.status} />
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <></>
      </TableCell>
    </TableRow>
  );
};
