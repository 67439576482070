import { client } from "api/client.api";
import { JobModel, List } from "models";
import { ServerErrorType } from "api/api.types";
import { FailedCounterResponse, GetJobsQuery, JobSuppression, SuppressionDetail } from "api/jobs/jobs.types";
import { BasePaginationQuery, BaseSearchQuery } from "types";

export const getJobs = client.createRequest<List<JobModel>, null, ServerErrorType, GetJobsQuery>()({
  method: "GET",
  endpoint: "/suppressions/jobs",
  options: { timeout: 0 },
});

export const getJob = client.createRequest<JobModel>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId",
  options: { timeout: 0 },
});

export const getFailedJobsCounter = client.createRequest<FailedCounterResponse>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/values/:valueId/failed-counters",
});

export const getJobValues = client.createRequest<
  List<JobSuppression>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery & { "order[value]": string; status?: string }
>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/values",
  options: { timeout: 0 },
});

export const getSuppressionValueProjects = client.createRequest<
  List<SuppressionDetail>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery & { status?: string }
>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/values/:valueId/projects",
  options: { timeout: 0 },
});

export const getSuppressionsValueDataExtensions = client.createRequest<
  List<SuppressionDetail>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery & { status?: string }
>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/values/:valueId/data-extensions",
  options: { timeout: 0 },
});

export const getSuppressionsValueRemarketyAccounts = client.createRequest<
  List<SuppressionDetail>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery & { status?: string }
>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/values/:valueId/remarkety-accounts",
  options: { timeout: 0 },
});

export const getSuppressionsValueInsiderAccounts = client.createRequest<
  List<SuppressionDetail>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery & { status?: string }
>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/values/:valueId/insider-accounts",
  options: { timeout: 0 },
});

export const getSuppressionsValueGreenArrowAccounts = client.createRequest<
  List<SuppressionDetail>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery & { status?: string }
>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/values/:valueId/greenarrow-accounts",
  options: { timeout: 0 },
});

export const getSuppressionsValueMarketoAccounts = client.createRequest<
  List<SuppressionDetail>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery & { status?: string }
>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/values/:valueId/marketo-accounts",
  options: { timeout: 0 },
});

export const getSuppressionsValueBlueshiftAccounts = client.createRequest<
  List<SuppressionDetail>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery & { status?: string }
>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/values/:valueId/blueshift-accounts",
  options: { timeout: 0 },
});

export const terminateJob = client.createRequest<JobModel>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/abort",
});
