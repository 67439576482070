import { object, string, Schema, boolean } from "yup";

import { CreateOptizmoCompanyData } from "./create-optizmo-company.types";

export const validationSchema: Schema<CreateOptizmoCompanyData> = object().shape({
  name: string().required("Name is required"),
  optizmoBased: boolean().required(),
  optizmoToken: string().when("optizmoBased", ([optizmoBased], schema) => {
    if (optizmoBased) return schema.required("Optizmo token is required");
    return schema.notRequired().strip();
  }),
});

export const initialValues: CreateOptizmoCompanyData = {
  name: "",
  optizmoBased: true,
  optizmoToken: "",
};
