import { ColumnsType } from "types";

export const TABLE_NAME = "remarkety-by-accounts";

export const accountsColumns: ColumnsType = [
  { label: "Account", queryKey: "name" },
  { label: "Integration" },
  { label: "Success", required: true, queryKey: "success" },
  { label: "Failed", required: true, queryKey: "failed" },
  { label: "In progress", required: true, queryKey: "inProgress" },
];
