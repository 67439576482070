import { array, number, object, Schema } from "yup";

import { ExportData } from "./export.types";

export const initialValues: ExportData = {
  companyIds: [],
  clientIds: [],
  offerIds: [],
};

export const validationSchema: Schema<ExportData> = object().shape({
  companyIds: array()
    .of(number().required())
    .min(1, "At least one company is required")
    .required("Company is required"),
  clientIds: array().of(number().required()).min(1, "At least one client is required").required("Client is required"),
  offerIds: array().of(number().required()).min(1, "At least one offer is required").required("Offer is required"),
});
