import React, { useState } from "react";
import { useFetch } from "@hyper-fetch/react";
import { Pagination, Search, SelectOption, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useDebounce } from "@better-hooks/performance";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { JobModel, List } from "models";
import { getInitialStorageFilters } from "utils";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { initialFilters, suppressionValuesColumns, TABLE_NAME } from "./suppression-values.constants";
import { getJobValues, JobSuppression } from "api";
import { SuppressionValueItem } from "./suppression-value-item/suppression-value-item";
import { SuppressDetails } from "../suppress-details/suppress-details";
import { JobSocketInstance, ValueUpdateData } from "hooks";
import { StatusFilter, TableError, TableNoContent } from "components";

import styles from "./suppression-values.module.scss";

type SuppressionValuesProps = {
  job: JobModel;
  socket: JobSocketInstance | null;
};

export const SuppressionValues: React.FC<SuppressionValuesProps> = ({ job, socket }) => {
  const { debounce } = useDebounce({ delay: 1200 });

  const [suppressToPreview, setSuppressToPreview] = useState<JobSuppression | null>(null);
  const [jobSuppressions, setJobSuppressions] = useState<List<JobSuppression> | null>(null);

  const { state, query, setQueryParams, currentState, setValue } = useFilters<
    BasePaginationQuery & BaseSearchQuery & { status?: string }
  >({
    ...getInitialStorageFilters<BasePaginationQuery & BaseSearchQuery & { status?: string }>(
      TABLE_NAME,
      initialFilters,
    ),
    isStateBased: true,
  });

  const { loading, error, refetch, onSuccess } = useFetch(
    getJobValues.setParams({ jobId: job.id }).setQueryParams({ ...state, "order[value]": "asc", status: query.status }),
  );
  onSuccess(({ response }) => setJobSuppressions(response));

  const pagination = usePagination({
    listPayload: jobSuppressions,
    isStateBased: true,
    initialState: currentState,
  });

  useDidUpdate(
    () => {
      const suppressionValueUpdate = ({ suppressionValueId, status }: ValueUpdateData) => {
        setJobSuppressions((prevState) => {
          if (!prevState) return null;
          const newData = prevState.data.map((suppressionValue) => {
            if (suppressionValue.id === suppressionValueId) {
              return { ...suppressionValue, status };
            }
            return suppressionValue;
          });

          return { ...prevState, data: newData };
        });
      };

      if (socket) {
        socket.on("valueUpdate", suppressionValueUpdate);

        return () => {
          socket.off("valueUpdate", suppressionValueUpdate);
        };
      }
    },
    [socket],
    true,
  );

  const handleRowClick = (value: JobSuppression) => {
    setSuppressToPreview(value);
  };

  const handleStatusChange = (value: SelectOption<string> | null) => {
    if (value) {
      setQueryParams({ ...query, limit: "50", offset: "0", search: "", status: value.value });
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            <h2 className={styles.label}>Job suppression values</h2>
            <p className={styles.subtitle}>
              All emails that were added to the suppressions.
              <br />
              Click on email to see more details based on project and data extension.
            </p>

            <StatusFilter
              type="values"
              onChange={handleStatusChange}
              selectedStatus={query?.status || ""}
              className={styles.filter}
            />
          </div>

          <div className={styles.filtersWrapper}>
            <div className={styles.searchWrapper}>
              <Search
                searchValue={state.search}
                setSearch={(value) => {
                  debounce(() => setValue("search")(value));
                }}
                onClearClick={() => setValue("search")("")}
                width="small"
                className={styles.search}
              />
            </div>
          </div>
        </div>

        <div className={styles.content}>
          <Table
            entityName={TABLE_NAME}
            columns={suppressionValuesColumns}
            list={jobSuppressions?.data}
            error={error?.message}
            refresh={() => refetch()}
            loading={loading}
            resetColumnsOnMount={false}
            className={styles.table}
            contentClassName={styles.tableContent}
            customNoContent={<TableNoContent />}
            customError={<TableError description={error?.message} />}
            row={(suppressionValue, index) => (
              <SuppressionValueItem value={suppressionValue} onRowClick={handleRowClick} index={index} />
            )}
          />
        </div>
      </div>

      <div className={styles.pagination}>
        <Pagination
          {...pagination}
          onPageChange={(page, offset) => {
            pagination.onPageChange(page, offset);
            setValue("offset")(offset);
          }}
          onElementsPerPageChange={(value) => {
            pagination.onElementsPerPageChange(value);
            setValue("limit")(value);
          }}
        />
      </div>

      <SuppressDetails
        job={job}
        suppression={suppressToPreview}
        isOpen={Boolean(suppressToPreview)}
        handleClose={() => setSuppressToPreview(null)}
        socket={socket}
      />
    </div>
  );
};
