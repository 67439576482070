import React, { useContext, useMemo, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { useFormikContext } from "formik";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { FormField, Label, SelectOption } from "@epcnetwork/core-ui-kit";
import { TriangleAlert } from "lucide-react";

import { DataExtensionFieldsResponse, getAllActiveDataExtensions } from "api";
import { OptizmoExportData, SalesforceData, SalesforceDataExtensionData } from "../../../list-cleaning.types";
import { FieldsFrom } from "./fields-from/fields-from";
import { FieldsTo } from "./fields-to/fields-to";
import { OptizmoExportContext } from "../../../list-cleaning.context";

import styles from "../../../list-cleaning.module.scss";

export const DataExtensions: React.FC = () => {
  const { dataExtensionsError, setDataExtensionsError } = useContext(OptizmoExportContext);
  const { values, setFieldValue, setFieldTouched } = useFormikContext<OptizmoExportData>();

  const [fromFields, setFromFields] = useState<DataExtensionFieldsResponse[]>([]);
  const [toFields, setToFields] = useState<DataExtensionFieldsResponse[]>([]);
  const [dataExtensionsOptions, setDataExtensionsOptions] = useState<SelectOption<string>[]>([]);

  const espData = values.espData as SalesforceData;
  const fromListValues = espData.data.fromList as SalesforceDataExtensionData;
  const toListValues = espData.data.toList as SalesforceDataExtensionData;

  const activeDataExtensions = useSubmit(getAllActiveDataExtensions);
  activeDataExtensions.onSubmitSuccess(async ({ response }) => {
    const options: SelectOption<string>[] = response.map((dataExtension) => ({
      label: dataExtension.Name,
      value: dataExtension.CustomerKey,
    }));
    setDataExtensionsOptions(options);

    if (options?.[0]) {
      const option = options[0];
      await setFieldValue("espData.data.fromList.customerKey", option.value);
      await setFieldValue("espData.data.fromList.name", option ? option.label : "");
      setTimeout(() => setFieldTouched("espData.data.fromList.name", true));
    }

    if (options?.[1]) {
      const option = options[1];
      await setFieldValue("espData.data.toList.customerKey", option.value);
      await setFieldValue("espData.data.toList.name", option ? option.label : "");
      setTimeout(() => setFieldTouched("espData.data.toList.name", true));
    }
  });

  useDidUpdate(
    () => {
      if (espData.businessUnitId) {
        activeDataExtensions.submit({ params: { businessUnitId: espData.businessUnitId.toString() } });
      }
    },
    [espData.businessUnitId],
    true,
  );

  const handleNameChange = (fieldKey: string) => async (option: SelectOption | null) => {
    // we only need to set the name - id is set automatically from FormField
    await setFieldValue(`espData.data.${fieldKey}.name`, option ? option.label : "");
    setTimeout(() => setFieldTouched(`espData.data.${fieldKey}.name`, true));
  };

  const fromOptions = useMemo(() => {
    // filter already selected value in the 2nd field
    const fromListData = espData.data.toList as SalesforceDataExtensionData;
    if (fromListData?.customerKey)
      return dataExtensionsOptions.filter((option) => option.value !== fromListData.customerKey);

    return dataExtensionsOptions;
  }, [espData.data.toList, dataExtensionsOptions]);

  const toOptions = useMemo(() => {
    // filter already selected value in the 2nd field
    const toListData = espData.data.fromList as SalesforceDataExtensionData;
    if (toListData?.customerKey)
      return dataExtensionsOptions.filter((option) => option.value !== toListData.customerKey);
    return dataExtensionsOptions;
  }, [espData.data.fromList, dataExtensionsOptions]);

  useDidUpdate(
    () => {
      if (fromFields.length && toFields.length && fromListValues?.customerKey && toListValues?.customerKey) {
        if (fromFields.length !== toFields.length) {
          setDataExtensionsError(true);
        } else {
          const fieldsMatch = fromFields.map((field) => {
            return toFields.some(
              (toField) =>
                toField.Name === field.Name &&
                toField.FieldType === field.FieldType &&
                toField.IsRequired === field.IsRequired,
            );
          });

          const haveUnmatchedFields = fieldsMatch.some((match) => !match);
          setDataExtensionsError(haveUnmatchedFields);
        }
      } else {
        setDataExtensionsError(false);
      }

      return () => {
        setDataExtensionsError(false);
      };
    },
    [fromFields, toFields, fromListValues, toListValues],
    true,
  );

  return (
    <div className={styles.selectGrid} style={{ marginTop: "24px" }}>
      {/*FROM*/}
      <div>
        <div>
          <div className={styles.row}>
            <div className={styles.step}>7</div>
            <div>
              <Label text="From data extension" isInputLabel />
              <p className={styles.projectDescription}>Select initial data extension</p>
            </div>
          </div>
        </div>

        <div>
          <div>
            <p className={styles.projectDescription}>Select a data extension</p>
          </div>

          <FormField
            type="select"
            name="espData.data.fromList.customerKey"
            options={fromOptions}
            isSearchable
            onChange={handleNameChange("fromList")}
            searchPlaceholder="Search data extensions"
            disabled={!espData?.businessUnitId || activeDataExtensions.submitting}
            asyncOptions={{
              loading: activeDataExtensions.submitting,
            }}
            isMulti={false}
          />

          <FieldsFrom setFromFields={setFromFields} />
        </div>
      </div>

      {/*TO*/}
      <div>
        <div>
          <div className={styles.row}>
            <div className={styles.step}>8</div>
            <div>
              <Label text="To data extension" isInputLabel />
              <p className={styles.projectDescription}>Select target data extension</p>
            </div>
          </div>
        </div>

        <div>
          <div>
            <p className={styles.projectDescription}>Select a data extension</p>
          </div>

          <FormField
            type="select"
            name="espData.data.toList.customerKey"
            options={toOptions}
            isSearchable
            onChange={handleNameChange("toList")}
            searchPlaceholder="Search data extensions"
            disabled={!espData?.businessUnitId || activeDataExtensions.submitting}
            asyncOptions={{
              loading: activeDataExtensions.submitting,
            }}
            isMulti={false}
          />

          <FieldsTo setToFields={setToFields} />
        </div>
      </div>

      {dataExtensionsError && (
        <div className={styles.alert}>
          <TriangleAlert width={16} height={16} />
          Data extensions need to have the same field structure. These two data extensions do not match.
        </div>
      )}
    </div>
  );
};
