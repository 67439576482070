import React from "react";
import { useFormikContext } from "formik";
import { Label } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";

import { TabOption, Tabs } from "components";
import { SalesforceUnsub } from "./salesforce/salesforce-unsub";
import { IterableUnsub } from "./iterable/iterable-unsub";
import { RemarketyUnsub } from "./remarkety/remarkety-unsub";
import { MarketoUnsub } from "./marketo/marketo-unsub";
import { InsiderUnsub } from "./insider/insider-unsub";
import { GreenArrowUnsub } from "./green-arrow/green-arrow-unsub";
import { BlueshiftUnsub } from "./blueshift/blueshift-unsub";
import {
  initialSalesforceData,
  initialIterableData,
  initialRemarketyData,
  initialMarketoData,
  initialGreenArrowData,
  initialInsiderData,
  initialBlueshiftData,
} from "../create-unsub.constants";
import { getActiveEsps, UnsubscribeFormData } from "api";
import { Blueshift, GreenArrow, Insider, Iterable, Marketo, Remarkety, Salesforce } from "assets";
import { Integration } from "types";

import styles from "./unsub-list-select.module.scss";

export const UnsubListSelect: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<UnsubscribeFormData>();

  const { data: activeEsps, onSuccess } = useFetch(getActiveEsps);
  onSuccess(async ({ response }) => {
    const firstActive = Object.entries(response).find(([key, value]) => value)?.[0];
    if (firstActive) await setFieldValue("data.esp", firstActive as Integration);
  });

  const handleTabChange = async ({ value }: TabOption<Integration>) => {
    await setFieldValue("data.esp", value);
    if (value === "iterable") await setFieldValue("data.properties", initialIterableData);
    if (value === "salesforce") await setFieldValue("data.properties", initialSalesforceData);
    if (value === "remarkety") await setFieldValue("data.properties", initialRemarketyData);
    if (value === "marketo") await setFieldValue("data.properties", initialMarketoData);
    if (value === "greenarrow") await setFieldValue("data.properties", initialGreenArrowData);
    if (value === "insider") await setFieldValue("data.properties", initialInsiderData);
    if (value === "blueshift") await setFieldValue("data.properties", initialBlueshiftData);
  };

  const options: (TabOption<Integration> & { espActive: boolean })[] = [
    { value: "iterable", label: "Iterable", icon: <Iterable />, espActive: activeEsps?.iterable || false },
    { value: "salesforce", label: "Salesforce", icon: <Salesforce />, espActive: activeEsps?.salesforce || false },
    { value: "remarkety", label: "Remarkety", icon: <Remarkety />, espActive: activeEsps?.remarkety || false },
    { value: "marketo", label: "Marketo", icon: <Marketo />, espActive: activeEsps?.marketo || false },
    {
      value: "greenarrow",
      label: "GreenArrow",
      icon: <GreenArrow style={{ flexShrink: 0, width: "15px" }} />,
      espActive: activeEsps?.greenarrow || false,
    },
    { value: "insider", label: "Insider", icon: <Insider />, espActive: activeEsps?.insider || false },
    { value: "blueshift", label: "Blueshift", icon: <Blueshift />, espActive: activeEsps?.blueshift || false },
  ];

  const activeIntegrationOptions = options.filter((option) => option.espActive);

  return (
    <div>
      <Label text="Select ESP" isInputLabel />
      <Tabs
        selected={values.data.esp}
        onChange={handleTabChange}
        options={activeIntegrationOptions}
        className={styles.tabs}
        buttonClassName={styles.tabButton}
      />

      {values.data.esp === "iterable" && <IterableUnsub />}
      {values.data.esp === "salesforce" && <SalesforceUnsub />}
      {values.data.esp === "remarkety" && <RemarketyUnsub />}
      {values.data.esp === "marketo" && <MarketoUnsub />}
      {values.data.esp === "greenarrow" && <GreenArrowUnsub />}
      {values.data.esp === "insider" && <InsiderUnsub />}
      {values.data.esp === "blueshift" && <BlueshiftUnsub />}
    </div>
  );
};
