import React, { useState } from "react";
import { Pagination, Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useDebounce } from "@better-hooks/performance";

import { MarketoTableItem } from "./marketo-table-item";
import { accountsColumns, TABLE_NAME } from "./marketo-table.constants";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { getGroupedMarketoAccounts } from "api";
import { DashboardEventData, DashboardSocketInstance } from "pages/dashboard/dashboard.types";
import { List } from "models";
import { MarketoAccountsStatsState } from "api/dashboard/dashboard.types";
import { initialFilters } from "../suppressions-status.constants";
import { getInitialStorageFilters } from "utils";
import { TableError, TableNoContent } from "components";

import styles from "../suppressions-status.module.scss";

interface Props {
  socket: DashboardSocketInstance | null;
}

export const MarketoTable: React.FC<Props> = ({ socket }) => {
  const { debounce } = useDebounce({ delay: 1200 });
  const { query, state, currentState, setValue } = useFilters<BasePaginationQuery & BaseSearchQuery>({
    ...getInitialStorageFilters<BasePaginationQuery & BaseSearchQuery>(TABLE_NAME, initialFilters),
  });

  const [dashboardProjects, setDashboardProjects] = useState<List<MarketoAccountsStatsState> | null>(null);

  const { error, loading, refetch, onSuccess } = useFetch(
    getGroupedMarketoAccounts.setQueryParams({ ...state, ...query }),
  );
  onSuccess(({ response }) => {
    const projects: List<MarketoAccountsStatsState> = {
      ...response,
      data: response.data.map((project) => {
        return {
          ...project,
          inProgress: +project.inProgress,
          success: +project.success,
          failed: +project.failed,
          created: 0,
          aborted: 0,
        };
      }),
    };
    setDashboardProjects(projects);
  });

  const pagination = usePagination({
    listPayload: dashboardProjects,
    isStateBased: true,
    initialState: currentState,
  });

  useDidUpdate(
    () => {
      const handleProjectUpdate = ({ id, prevStatus, newStatus }: DashboardEventData) => {
        setDashboardProjects((prevState) => {
          if (!prevState) return prevState;

          const newData = prevState.data.map((project) => {
            if (project.id === id) {
              if (newStatus === "aborted" && prevStatus && prevStatus !== "created") {
                return {
                  ...project,
                  [prevStatus]: +project[prevStatus] > 0 ? +project[prevStatus] - 1 : project[prevStatus],
                };
              }

              if (prevStatus && prevStatus !== "created") {
                return {
                  ...project,
                  [prevStatus]: +project[prevStatus] > 0 ? +project[prevStatus] - 1 : project[prevStatus],
                  [newStatus]: project[newStatus] + 1,
                };
              }
              return { ...project, [newStatus]: project[newStatus] + 1 };
            }
            return project;
          });

          return { ...prevState, data: newData };
        });
      };

      if (socket) {
        socket.on("dashboardMarketoAccountUpdate", handleProjectUpdate);

        return () => {
          socket.off("dashboardMarketoAccountUpdate", handleProjectUpdate);
        };
      }
    },
    [socket],
    true,
  );

  return (
    <div>
      <div className={styles.filtersWrapper}>
        <div className={styles.searchWrapper}>
          <Search
            searchValue={query.search}
            setSearch={(value) => {
              debounce(() => setValue("search")(value));
            }}
            onClearClick={() => setValue("search")("")}
            width="small"
            className={styles.search}
          />
        </div>
      </div>
      <Table
        entityName={TABLE_NAME}
        columns={accountsColumns}
        list={dashboardProjects?.data}
        error={error?.message}
        refresh={() => refetch()}
        loading={loading}
        resetColumnsOnMount={false}
        className={styles.table}
        contentClassName={styles.tableContent}
        customNoContent={<TableNoContent />}
        customError={<TableError description={error?.message} />}
        row={(data, index) => <MarketoTableItem data={data} index={index} />}
      />
      <div className={styles.pagination}>
        <Pagination
          {...pagination}
          onPageChange={(page, offset) => {
            pagination.onPageChange(page, offset);
            setValue("offset")(offset);
          }}
          onElementsPerPageChange={(value) => {
            pagination.onElementsPerPageChange(value);
            setValue("limit")(value);
          }}
        />
      </div>
    </div>
  );
};
