import React from "react";
import { Select, SelectOption } from "@epcnetwork/core-ui-kit";
import cn from "classnames";

import { ValueType, options } from "./status-filter.constants";

import styles from "./status-filter.module.scss";

export type StatusFilterProps = {
  selectedStatus: string;
  onChange: (option: SelectOption<string> | null) => void;
  type: ValueType;
  className?: string;
};

export function StatusFilter({ type, selectedStatus, onChange, className }: StatusFilterProps) {
  const statusOptions = options[type];

  const handleStatusChange = (value: SelectOption<string> | null) => {
    onChange?.(value);
  };

  return (
    <div className={cn(styles.filters, className)}>
      <Select
        options={statusOptions}
        selectedOptionsKeys={selectedStatus}
        onChange={handleStatusChange}
        inputSize="small"
        label=""
        disableClearing
        sortOptions={false}
        className={styles.select}
        data-testid="suppression-stauts-select"
        disableError
      />
    </div>
  );
}
