import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Jobs list page</h2>
    <p>
      Here, all your suppression tasks are displayed along with their current status. This page provides a centralized
      view of every suppression job you’ve initiated.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Jobs list items</h2>

    <p>
      Each job item displayed here is created for all <strong>active projects</strong> in Iterable and all{" "}
      <strong>active data extensions</strong> in Salesforce, ensuring that every suppression task covers the full scope
      of your operations.
    </p>

    <p>You can click on each job item, to see suppression emails and their current status.</p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".jobs-list-navbar", on: "bottom-start" },
    permission: ["read", "jobs"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "jobs"],
  },
];
