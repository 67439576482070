import { client } from "api/client.api";
import { BlueshiftAccountModel, List } from "models";
import { GetAccountsQuery } from "api/iterable/accounts";
import { DeleteResponse, ServerErrorType } from "api/api.types";
import { BlueshiftAccountData, UpdateBlueshiftAccountData } from "./blueshift.types";

export const createBlueshiftAccount = client.createRequest<BlueshiftAccountModel, BlueshiftAccountData>()({
  method: "POST",
  endpoint: "/blueshift/accounts",
});

export const getBlueshiftAccounts = client.createRequest<
  List<BlueshiftAccountModel>,
  null,
  ServerErrorType,
  GetAccountsQuery
>()({
  method: "GET",
  endpoint: "/blueshift/accounts",
  options: { timeout: 0 },
});

export const getBlueshiftActiveAccounts = client.createRequest<
  BlueshiftAccountModel[],
  null,
  ServerErrorType,
  GetAccountsQuery
>()({
  method: "GET",
  endpoint: "/blueshift/accounts/active",
  options: { timeout: 0 },
});

export const getBlueshiftAccount = client.createRequest<BlueshiftAccountModel>()({
  method: "GET",
  endpoint: "/blueshift/accounts/:accountId",
  options: { timeout: 0 },
});

export const updateBlueshiftAccount = client.createRequest<BlueshiftAccountModel, UpdateBlueshiftAccountData>()({
  method: "PUT",
  endpoint: "/blueshift/accounts/:accountId",
});

export const deleteBlueshiftAccount = client.createRequest<DeleteResponse>()({
  endpoint: "/blueshift/accounts/:accountId",
  method: "DELETE",
});
