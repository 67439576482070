import { object, string, Schema, number } from "yup";

import { GreenArrowAccountData } from "api";

export const validationSchema: Schema<GreenArrowAccountData> = object().shape({
  name: string().required("Name is required"),
  apiUrl: string().required("URL is required"),
  apiKey: string().required("API key is required"),
  suppressionListId: number().min(1, "Suppression list is required").required("Suppression list is required"),
});

export const initialValues: GreenArrowAccountData = {
  name: "",
  apiUrl: "",
  apiKey: "",
  suppressionListId: 0,
};
